export interface RequisitionFormMgrEntity {
  id: number,
  reqFormId: number | null,
  reqFormNo: string | null,
  mgrPosition: string | null,
  mgrName: string | null,  
  confirmDatetime: Date | null,

  [key: string]: string | boolean | number | null | undefined | Object

}

export const EMPTY_REQUISITION_FORM_MGR_ENTITY: RequisitionFormMgrEntity = {
  id: 0,
  reqFormId: null,
  reqFormNo: null,
  mgrPosition: null,
  mgrName: null,
  confirmDatetime: null
}