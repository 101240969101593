import { EMPTY_SYSTEM_PREFERENCE_MODEL } from "presentation/model/SystemPreference/SystemPreferenceModel";
import { useState } from "react";
import { createContainer } from "react-tracked";

const {
    Provider: SystemPreferenceProvider,
    useTracked: useSystemPreferenceTracked
} = createContainer(() => useState(EMPTY_SYSTEM_PREFERENCE_MODEL), { concurrentMode: true });
export { SystemPreferenceProvider, useSystemPreferenceTracked };

