import _ from "lodash";
import { formatNumber, formatNumberStr, formatStrForEditable, formatStrToNumber, formatStrToNumberForCom, validateNumberStr } from "presentation/utils/numberUtil";
import { memo, useEffect, useState } from "react";
import { CommonField, FieldType, InputField } from "veronica-ui-component/dist/component/core";

interface NumberInputProps {
    value: string | number;
    decimalPlaces?: number;
    disabled?: boolean,
    onChange?: (event: React.ChangeEvent<HTMLInputElement>, fieldName:string, newValue: any) => void;
    width?:string,
    label?: string,
    errorMessage?: string,
    fieldName?: string,
    optional?: boolean,
    maxLength?: number,
    required?: boolean;
}

const NumberInputComponent: React.FC<NumberInputProps> = (props) => {    
    const [inputValue, setInputValue] = useState<string | undefined | null>();
    const [inputByUser, setInputByUser] = useState<boolean>(false);
    const [inputChinese, setInputChinese] = useState<boolean>(false);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(inputChinese){
            setInputValue(event.target.value);
            return;
        }
        if (!!!event.target.value) {
            setInputValue('');
            if (props.onChange && props.fieldName) {
                props.onChange(event, props.fieldName, null);
            }
            return;
        };

        setInputByUser(true);

        // const newValue = formatNumberStr(event.target.value, props.decimalPlaces??0, false, true);
        const newValue = event.target.value;
        if (!validateNumberStr(newValue, props.decimalPlaces??0)) return;

        setInputValue(newValue);
        const numberVal = formatStrToNumber(newValue);        
        if (!!props.onChange && !!props.fieldName) {
            props.onChange(event, props.fieldName, numberVal);
        }
    };

    const handleFocus = () => {
        if (inputValue) {
            const newValue = formatStrForEditable(inputValue);;
            setInputValue(newValue?.toString());
        }
        setInputByUser(true);
    }

    const handleBlur = () => {
        if (inputValue) {
            const newValue = formatNumberStr(inputValue, props.decimalPlaces??0, false, true);
            setInputValue(newValue);
        }
        setInputByUser(false);
    }
    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const key = event.key;
        const isDecimalSeparatorKey = key === ',';
        const isDigitKey = /^\d$/.test(key);
        const isChineseCharacter = key === "Process";
    
        if (!isDecimalSeparatorKey && !isDigitKey && isChineseCharacter) {
            setInputValue(inputValue);
        }
    };

    useEffect(() => {
        if (inputByUser) return;

        let convertVal = "";
        if (props.value !== null && props.value !== '') {
             
            let formattedStr = formatStrToNumberForCom(props.value?.toString());
            if (_.isNumber(formattedStr)) {
                convertVal = formatNumber((formattedStr as unknown as number), props.decimalPlaces??0);
                setInputValue(convertVal);
            } else {
                convertVal = formatNumberStr(formattedStr as string, props.decimalPlaces??0, false, true);
                setInputValue(convertVal);
            }
        } else {
            setInputValue(convertVal);
        }        
    }, [inputByUser, inputValue, props.decimalPlaces, props.value]);

    return <div>
        {(!props.disabled) ?        
        <InputField
            label={props.label}
            type={'text'}
            value={inputValue??""}
            maxLength={props.maxLength ? props.maxLength : 50}
            width={props.width ? props.width : "100%"}
            errorMessage={props.errorMessage}
            optional={props.optional?props.optional:false}
            onChange={handleInputChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            inputMode={"decimal"}
            onCompositionStart = {()=>{
                setInputChinese(true);
            }}
            onCompositionEnd = {(e:any)=>{
                setInputChinese(false);
                setInputValue(e.target.value.replace(/[^0-9,]/g, ''));
            }}
             /> :
        <CommonField
            isReadOnly={true}
            readOnlyValue={inputValue??""}
            fieldLabel={props.label?props.label:''}
            fieldType={FieldType.TEXT}
            fieldKey={''}                
            requiredFieldList={[]}
            onFieldChange={() => {}} />
        }
    </div>
};

export default memo(NumberInputComponent);