export const DocumentIssuanceConstant = {
    CATEGORY: "Invoice",
    TITLE: "Document Issuance",
    priority: "Priority",
    BILL_TO_COMPANY: "Bill-to Company",
    CUSTOMER_CODE: "Customer Code",
    CHARGE_TYPE: "Charge Type",
    ISSUE_TYPE: "Issue Type",
    DOC_TYPE: "Doc Type",
    NO_OF_COPIES: "No. Of Copies",
    NO_OF_SUPPORTING_DOC: "No. Of Supporting Doc.",
    NO_OF_ORIGINAL: "No. Of Original",
    ASTERISK_LABEL: "Asterisk Label",
    LANGUAGE: "Language",
    AMOUNT_PRINTING: "Amount Printing",
    DISPLAY_BILLING_MSG: "Display Billing Msg",
    DISPLAY_2ND_CURRENCY: "Display 2nd Currency",
    EMAIL_ADDRESS: "Email Address",
    CC: "Cc",
    EDI_EMAIL_ADDRESS: "Edi Email Address",
    REPORT_TEMPLATE: "Report Template",
    INVOICE_CURRENCY: "Invoice Currency",
    ACTIVE_IND: "Active Ind",

    Detail: {
        TITLE: "Supporting Document",
        CHARGE_TYPE: "Charge Type",
        DETAIL_PRINTING: "Detail Printing",
        SUPPORTING_DOCUMENT: "Supporting Document"
    }
}
