import { CompanyEntity } from "domain/entity/Company/CompanyEntity";
import _ from "lodash";
import { CompanySearchCriteria } from "presentation/constant/Company/CompanySearchCriteria";
import { axiosDeleteData, axiosGetData, axiosPostData, axiosPutData } from "../axios/AxiosBasicImpl";
import companyAxiosInstance from "../axios/companyAxiosInstance";
import { CompanyRepository } from "./CompanyRepo";

export const CompanyRepoImpl = (): CompanyRepository => {
    const url = '/v1/company';

    const getAllCompanyForCombobox = async (): Promise<CompanyEntity[]> => {
        const newUrl = url + '/getAllCompanyForCombobox';
        return axiosGetData(companyAxiosInstance, `${newUrl}`, []).then(res => {
            const data = res.data;
            return _.sortBy(data, ["key"]);
        }).catch(err => {
            return [];
        });
    }

    const getAllCompanies = async (): Promise<CompanyEntity[]> => {
        return axiosGetData(companyAxiosInstance, url, []).then(res => {
            const data = res.data;
            return _.sortBy(data, ["key"]);
        }).catch(err => {
            return [];
        });
    }

    const getCompanyByKey = async (key: string): Promise<CompanyEntity> => {
        return axiosGetData(companyAxiosInstance, `${url}/${key}`, []).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return null;
        });
    }
    const getCompanyByType = async (type: string): Promise<CompanyEntity[]> => {
        const newUrl = '/v1/companyByType';
        return axiosGetData(companyAxiosInstance, `${newUrl}/${type}`, []).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return null;
        });
    }

    const searchCompany = async (searchCriteria: CompanySearchCriteria): Promise<CompanyEntity[]> => {
        const newUrl = url + '/search';
        const result = axiosPostData(companyAxiosInstance, `${newUrl}`, searchCriteria).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            let errorMessage = "Update Company Failure"
            if (err && err.response && err.response.data && typeof (err.response.data) === "string") {
                if (err.response.data.includes("already exist")) {
                    //errorMessage = `"Company ${newData.companyCode}" already exists.`
                }
            }
            throw new Error(errorMessage);
        });
        return result;
    }

    const createNewCompany = async (newData: CompanyEntity): Promise<CompanyEntity> => {
        const newUrl = url + '/add';

        return await axiosPostData(companyAxiosInstance, `${newUrl}`, newData).then(res => {
            if (res.msg === "OK") {
                return res.data;
            } else {
                let errorMessage = "Failed to add company."
                if (res.data && typeof (res.data) === "string") {
                    errorMessage = res.data;
                }
                return "Error:" + errorMessage;
            }
        }).catch(err => {
            return "Error:" + err.Message;
        });
    }

    const updateCompany = async (updatedData: CompanyEntity): Promise<CompanyEntity> => {
        const newUrl = url + '/update';

        return await axiosPutData(companyAxiosInstance, `${newUrl}`, updatedData).then(res => {
            if (res.msg === "OK") {
                return res.data;
            } else {
                let errorMessage = "Failed to update company."
                if (res.data && typeof (res.data) === "string") {
                    errorMessage = res.data;
                }
                return "Error:" + errorMessage;
            }
        }).catch(err => {
            return "Error:" + err.Message;
        });
    }

    const deleteCompanyByKey = async (key: string): Promise<boolean> => {
        return axiosDeleteData(companyAxiosInstance, `${url}`, key).then(res => {
            return res.status === 200
        }).catch(error => {
            return false
        })
    }

    const confirmCompanies = async (selectRows: CompanyEntity[]): Promise<Boolean> => {
        const newUrl = url + '/confirm';
        const result = axiosPostData(companyAxiosInstance, `${newUrl}`, selectRows).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            let errorMessage = "Confirm Company Failure"
            /*if (err && err.response && err.response.data && typeof (err.response.data) === "string") {
                if (err.response.data.includes("already exist")) {
                    errorMessage = `"Company ${selectRows.companyCode}" already exists.`
                }
            }*/
            throw new Error(errorMessage);
        });
        return result;
    }

    return {
        getAllCompanyForCombobox: getAllCompanyForCombobox,
        getAllCompanies: getAllCompanies,
        getCompanyByKey: getCompanyByKey,
        getCompanyByType: getCompanyByType,
        searchCompany: searchCompany,
        createNewCompany: createNewCompany,
        updateCompany: updateCompany,
        deleteCompanyByKey: deleteCompanyByKey,
        confirmCompanies: confirmCompanies
    }
}