import styled from 'styled-components';

export const CommonFieldWrapper = styled.div`
  &.common-field {
    // & .help_text {
    //   margin-top: 23px;
    // }

    // &>div>input {
    //   height: 37px !important;
    // }

    & .input-range {
      &>div>div>div {
        position: unset;
      }

      & input {
        height: 37px;
      }

      & div:last-child {
        & input {
          padding-top: 6px;
          padding-bottom: 6px;
        }

        & span {
          padding-top: 6px;
          padding-bottom: 0;
        }
      }

      & em {
        margin-top: 2.55rem !important;
      }
    }

    &.capitalize {
      & input {
        text-transform: uppercase;
      }
    }
  }
`;
