import { useCallback, useState } from "react";
import { HPHButton, IconButton, Loader } from "veronica-ui-component/dist/component/core";
import { SidebarActionBar, SidebarActionCross, SidebarTitle, Sidebarheader } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";
import RequisitionFormSearchForm from "./RequisitionFormSearchForm";
import { RequisitionFormConstant } from "presentation/constant/RequisitionForm/RequisitionFormConstant";
import { useRequisitionFormMaintenanceTracked } from "presentation/store/RequisitionForm/RequisitionFormMaintenanceProvider";
import { RequisitionFormSearchCriteria } from "domain/entity/RequisitionForm/RequisitionFormSearchCriteria";
import { useRequisitionFormVM } from "presentation/hook/RequisitionForm/useRequisitionFormVM";

const RequisitionFormSearchPanel = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const REQ_FORM_CONSTANT = RequisitionFormConstant.Header;
    const [ requisitionFormState ] = useRequisitionFormMaintenanceTracked();
    const requisitionFormVM = useRequisitionFormVM();

    const resetButtonClicked = useCallback(() => {
        requisitionFormVM.onSearchCriteriaResetClick();
    }, [requisitionFormVM]);
    
    const searchButtonClicked = useCallback((criteria:RequisitionFormSearchCriteria) => {
        setIsLoading(true);
        requisitionFormVM.getRequisitionForms(criteria).then((data) => {
            setIsLoading(false);
        }).catch(error => {
            setIsLoading(false);
        })
        
    }, [requisitionFormVM])

    return (
        <div className='side-form-content-left-wrapper' style={{padding:"20px 20px 20px 0px"}}>
            <div className={'flex-row'}>
                <div className={'flex-row-item flex-row-item-full-width'}>
                    <Sidebarheader>
                        <SidebarTitle>{REQ_FORM_CONSTANT.SEARCH_CRITERIA}</SidebarTitle>
                        <SidebarActionCross>
                            <IconButton
                                fileName="Icon-cross" size="medium" toolTipText='Close' toolTipArrow={false} onClick={requisitionFormVM.onSearchClick} />
                        </SidebarActionCross>
                    </Sidebarheader>                
                </div>
            </div>

            {isLoading && <Loader Indicator="Spinner" size="Medium" /> }

            <div className={'add-edit-form'} style={{ maxHeight:'75vh' , height:'75vh', overflow: 'auto'}}>
                <div className="flex-row-auto">
                    <div className={'flex-row-item'}>
                        
                        {/* Stack Form Start */}
                        <RequisitionFormSearchForm/>
                    </div>                  
                </div>
            </div>
            <SidebarActionBar>
                <HPHButton label={'Reset'} size={'Small'} theme={'Secondary'} onClick={resetButtonClicked} />
                <HPHButton label={'Search'} size={'Small'} theme={'Primary'} onClick={() => searchButtonClicked(requisitionFormState.searchCriteria)} />
            </SidebarActionBar>
        </div>
    );
}

export default RequisitionFormSearchPanel;