import { SystemPreferenceRepoImpl } from "domain/repository/SystemPreference/SystemPreferenceRepoImpl";
import { useSystemPreferenceTracked } from "presentation/store/SystemPreference/SystemPreferenceProvider";
import { SystemPreferenceVM } from "presentation/viewModel/SystemPreference/SystemPreferenceVM";
import { useMemo } from "react";

export const useSystemPreferenceVM = () => {
    const [, setSystemPreferenceState] = useSystemPreferenceTracked();
    const systemPreferenceVM = useMemo(() =>
        SystemPreferenceVM({
            dispatch: [setSystemPreferenceState],
            systemPreferenceRepo: SystemPreferenceRepoImpl(),
        }), [setSystemPreferenceState])

    return systemPreferenceVM;
}