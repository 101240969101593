export interface SystemPreferenceSearchCriteria {
    category?: 'GLOBAL' | 'USER';
    userId: string;
    type: 'Table' | 'Page';
    key: string;
    name?: string;
    onlySelfSetting: boolean;
}

export const EMPTY_SYSTEM_PREFERENCE_SEARCH_CRITERIA: SystemPreferenceSearchCriteria = {
    userId: '',
    type: 'Table',
    key: '',
    name: '',
    onlySelfSetting: true
}