import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { memo, useEffect, useState } from "react";
import { Loader, SliderPanel } from "veronica-ui-component/dist/component/core";
import RequisitionFormTitleBar from "./RequisitionFormTitleBar";
import { useRequisitionFormMaintenanceTracked } from "presentation/store/RequisitionForm/RequisitionFormMaintenanceProvider";
import { useRequisitionFormVM } from "presentation/hook/RequisitionForm/useRequisitionFormVM";
import { EMPTY_REQUISITION_FORM_SEARCH_CRITERIA } from "domain/entity/RequisitionForm/RequisitionFormSearchCriteria";
import RequisitionFormSearchPanel from "./Form/RequisitionFormSearchPanel";
import RequisitionFormTablePanel from "./Table/RequisitionFormTablePanel";

const RequisitionFormMaintenance: React.FC = () => {
    const [ requisitionFormState ] = useRequisitionFormMaintenanceTracked();
    const { isShowCriteriaPanel,isAllowAutoSearch,isBackFromDetail,searchCriteria } = requisitionFormState;
    const requisitionFormVM = useRequisitionFormVM();
    const [isLoading, setIsLoading] = useState(false);
    const [anaInfoState] = useANAInfoTracked();
    useEffect(() => {
        const initialScreen = async() => {   
            try {         
                setIsLoading(true);
                const results = await Promise.allSettled([
                    requisitionFormVM.loadDropdownOption(),
                    requisitionFormVM.getRequisitionForms({...EMPTY_REQUISITION_FORM_SEARCH_CRITERIA})
                ]);
                results.forEach((result, index) => {
                    if (index === 0 && result.status === 'fulfilled') {

                      } else if (index === 1 && result.status === 'rejected') {

                      }
                })
            } catch(error) {
                setIsLoading(false);
            }
        }
        isAllowAutoSearch && initialScreen().then((data) => {
            setIsLoading(false)
        }).catch(error => {            
            setIsLoading(false)
        });
    }, [anaInfoState.userName, isAllowAutoSearch, isBackFromDetail, searchCriteria, requisitionFormVM])

    
    if (isLoading) return <Loader Indicator="Stripe" size="Large" />;
    
    return <>

            <div className={`main-comp-wrapper${isShowCriteriaPanel ? '' : ' im-hide-side-form-draggable'}`}>
                <RequisitionFormTitleBar/>

                    <SliderPanel
                        isOpen={true}
                        draggable={false}
                        leftSectionWidth={isShowCriteriaPanel?"25%":"0%"}
                        rightSectionWidth={isShowCriteriaPanel?"75%":"100%"}
                        leftChildren={<RequisitionFormSearchPanel/>}
                        rightChildren={<RequisitionFormTablePanel/>} 
                        />
            </div>
        </>
}
export default memo(RequisitionFormMaintenance);