import { Permission } from "presentation/constant/ANAInfo/PermissionName";
import { RequisitionFormMaintenanceProvider } from "presentation/store/RequisitionForm/RequisitionFormMaintenanceProvider";
import { ANAInfoWrapper } from "presentation/view/components/ANAInfo/ANAInfoWrapper";
import { MessageBarWrapper } from "presentation/view/components/MessageBarWrapper";
import { RequisitionFormMasterView } from "presentation/view/section/RequisitionForm/RequisitionFormMasterView";

import { GridStyles } from "veronica-ui-component/dist/component/core/styled/table.styled";

const RequisitionFormContMain = () => {
    return <ANAInfoWrapper permission={Permission.REQUISITION_FORM}>
        <MessageBarWrapper>
            <RequisitionFormMaintenanceProvider>
                <GridStyles/>
                <RequisitionFormMasterView/>
            </RequisitionFormMaintenanceProvider>
        </MessageBarWrapper>
    </ANAInfoWrapper>
}

export default RequisitionFormContMain;