//no using now
export const isDevelopmentEnv = process.env.REACT_APP_ENVIRONMENT === 'development';
export const isProductionEnv = !isDevelopmentEnv;
export const isReferenceUsingKong = process.env.REACT_APP_REFERENCE_MODEL_USING_KONG === 'true';
export const isChargeUsingKong = process.env.REACT_APP_CHCARGE_MODEL_USING_KONG === 'true';
export const isAuthUsingKong = process.env.REACT_APP_AUTH_MODEL_USING_KONG === 'true';
export const isCompanyUsingKong = process.env.REACT_APP_COMPANY_MODEL_USING_KONG === 'true';
export const isTariffUsingKong = process.env.REACT_APP_TARIFF_MODEL_USING_KONG === 'true';
export const isDocumentUsingKong = process.env.REACT_APP_DOCUMENT_MODEL_USING_KONG === 'true';
export const isChargeStandAloneUsingKong = process.env.REACT_APP_CHARGE_STANDALONE_MODEL_USING_KONG === 'true';
export const isReportUsingKong = process.env.REACT_APP_REPORT_MODEL_USING_KONG === 'true';