import * as yup from 'yup';

export const commonRequisitionFormValidation: { [x: string]: any; } = {
  reqNature: yup.string().required("Missing"),
  reqMode: yup.string().required("Missing"),
  currencyCode: yup.string().required("Missing"),
  explanation1: yup.string().required("Missing"),
  reqFormDesc: yup.string().required("Missing")
};

export const commonRequisitionFormCustValidation: { [x: string]: any; } = {
  billToCompany: yup.string().required("Missing"),
  customerCode: yup.string().required("Missing")
};

export const requisitionFormValidation: { [x: string]: any; } = {
  ...commonRequisitionFormValidation,
};

export const requisitionFormCustValidation: { [x: string]: any; } = {
  ...commonRequisitionFormCustValidation,
};

export const requisitionFormValidationSchema = yup.object().shape(requisitionFormValidation);
export const requisitionFormCustValidationSchema = yup.object().shape(requisitionFormCustValidation);

