export interface RequisitionFormSearchCriteria{

    states?: string[] | null,
    billToCompanies?: string[] | null,
    reqNoFrom?: string | null,
    reqNoTo?: string | null,
    reqMode?: string | null,
    reqNature?: string | null,
    customerCode?: string | null,
    
    [key: string]: string[] | string | boolean | number | Date | null | undefined
}

export const EMPTY_REQUISITION_FORM_SEARCH_CRITERIA : RequisitionFormSearchCriteria = {

    states: [],
    billToCompanies: [],
    reqNoFrom: null,
    reqNoTo: null,
    reqMode: null,
    reqNature: null,
    customerCode: null,
}