import _ from "lodash";
import { Dispatch, SetStateAction } from "react";
import BaseViewModel from "../BaseViewModel";
import { RequisitionFormMaintenanceModel } from "presentation/model/RequisitionForm/RequisitionFormMaintenanceModel";
import { RequisitionFormRepository } from "domain/repository/RequisitionForm/RequisitionFormRepo";
import { EMPTY_REQUISITION_FORM_SEARCH_CRITERIA, RequisitionFormSearchCriteria } from "domain/entity/RequisitionForm/RequisitionFormSearchCriteria";
import { CompanyRepository } from "domain/repository/Company/CompanyRepo";
import { CustomerRepository } from "domain/repository/Company/CustomerRepo";
import { DropdownProps } from "presentation/model/DropdownProps";
import { ReqModeDroOpts } from "presentation/constant/DropDownOptions/Document/ReqModeDroOpts";
import { ParameterDetailRepository } from "domain/repository/parameter/ParameterDetailRepo";
import { ParameterCodeValue } from "presentation/constant/Parameter/ParameterCodeValue";
import { GroupCheckboxList, IFieldValue } from "veronica-ui-component/dist/component/core";
import { EMPTY_REQUISITION_FORM_ENTITY, RequisitionFormEntity } from "domain/entity/RequisitionForm/RequisitionFormEntity";
import { EMPTY_REQUISITION_FORM_CUSTOMER_ENTITY, RequisitionFormCustomerEntity } from "domain/entity/RequisitionForm/RequisitionFormCustomerEntity";
import { ExchangeRateRepository } from "domain/repository/ExchangeRate/ExchangeRateRepo";
import { Validation } from "presentation/constant/Validation";
import { requisitionFormCustValidationSchema, requisitionFormValidationSchema } from "presentation/constant/RequisitionForm/RequisitionFormValidationSchema";
import { EMPTY_REQUISITION_FORM_MGR_ENTITY } from "domain/entity/RequisitionForm/RequisitionFormMgrEntity";

interface RequisitionFormVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<RequisitionFormMaintenanceModel>> | ((value: SetStateAction<RequisitionFormMaintenanceModel>) => void),
    ],
    requisitionFormRepo: RequisitionFormRepository,
    companyRepo: CompanyRepository,
    customerRepo: CustomerRepository,
    parameterDtlRepo: ParameterDetailRepository,
    exchangeRateRepo: ExchangeRateRepository,
}

export const RequisitionFormVM = ({dispatch,requisitionFormRepo, companyRepo, customerRepo, parameterDtlRepo, exchangeRateRepo}:RequisitionFormVMProps) => {
    const [requisitionFormDispatch] = dispatch;

    const loadDropdownOption = async() => {
        await companyRepo.getAllCompanyForCombobox().then(
            companies => {
                let newCompanies = companies?.filter(company => company.companyType !== 'MASTER' && company.companyType !== 'ALLIANCE');

                let companyDropdownOptions = newCompanies?.map((company) => ({
                    dropdownLabel: company.companyCode,
                    tagLabel: company.id.toString(),
                    value: company.companyCode,
                })) ?? []
                companyDropdownOptions = _.orderBy(companyDropdownOptions, "dropdownLabel");

                requisitionFormDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        billToCompanyDropdownOptions: companyDropdownOptions
                    }
                }))
            }
        );

        await customerRepo.getCustomerActive().then(
            customers => {
                const sortCustomers = _.orderBy(customers, ["customerCode"]);
                let customerCodeDropdownOptions: DropdownProps[] = [];
                for (let i = 0; i < sortCustomers.length; i++) {
                    if (sortCustomers[i].id) {
                        customerCodeDropdownOptions.push({
                            dropdownLabel: sortCustomers[i].customerCode,
                            tagLabel: sortCustomers[i].id.toString(),
                            value: sortCustomers[i].customerCode
                        });
                    }
                }

                requisitionFormDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        customerCodeDropdownOptions: customerCodeDropdownOptions,
                    }
                }))
            }
        );

        await parameterDtlRepo.getAllParameterDtlsByParameterCode(ParameterCodeValue.REQ_NATURE).then(
            reqNatures => {
                const reqNatureDropdownOptions = reqNatures?.map((reqNature) => ({
                    dropdownLabel: reqNature.parameterDtlDesc??"-",
                    tagLabel: reqNature.parameterDtlCode,
                    value: reqNature.parameterDtlDesc??"-",
                })) ?? []

                requisitionFormDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        reqNatureDropdownOptions: reqNatureDropdownOptions,
                    }
                }))
            }
        )

        requisitionFormDispatch(prevState => ({
            ...prevState,
            dynamicOptions: {
                ...prevState.dynamicOptions,
                reqModeDropdownOptions: ReqModeDroOpts().getReqModeModel(),
            }
        }))
    }

    const loadDetailDropdownOption = async() => {
        await exchangeRateRepo.getAllCurrencies().then(
            currencies => {
                let currencyDropdownOptions = currencies?.map((currency) => ({
                    dropdownLabel: currency.currencyCode,
                    tagLabel: currency.currencyCode,
                    value: currency.currencyCode,
                })) ?? []

                requisitionFormDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        currencyDropdownOptions: currencyDropdownOptions,
                    }
                }))
            }
        )

        await parameterDtlRepo.getAllParameterDtlsByParameterCode(ParameterCodeValue.REQ_MGR_IN_CHARGE).then(
            mgrInCharges => {
                const mgrInChargeDropdownOptions = mgrInCharges?.map((mgrInCharge) => ({
                    dropdownLabel: mgrInCharge.parameterDtlCode,
                    tagLabel: mgrInCharge.parameterDtlCode,
                    value: mgrInCharge.parameterDtlCode,
                })) ?? []

                requisitionFormDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        managerInChgDropdownOptions: mgrInChargeDropdownOptions,
                    }
                }))
            }
        )

        await parameterDtlRepo.getAllParameterDtlsByParameterCode(ParameterCodeValue.REQ_ACCT_MGR).then(
            acctMgrs => {
                const acctMgrDropdownOptions = acctMgrs?.map((acctMgr) => ({
                    dropdownLabel: acctMgr.parameterDtlCode,
                    tagLabel: acctMgr.parameterDtlCode,
                    value: acctMgr.parameterDtlCode,
                })) ?? []

                requisitionFormDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        accountManagerDropdownOptions: acctMgrDropdownOptions,
                    }
                }))
            }
        )

        await parameterDtlRepo.getAllParameterDtlsByParameterCode(ParameterCodeValue.REQ_FIN).then(
            fins => {
                const finDropdownOptions = fins?.map((fin) => ({
                    dropdownLabel: fin.parameterDtlCode,
                    tagLabel: fin.parameterDtlCode,
                    value: fin.parameterDtlCode,
                })) ?? []

                requisitionFormDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        finDropdownOptions: finDropdownOptions,
                    }
                }))
            }
        )

        await parameterDtlRepo.getAllParameterDtlsByParameterCode(ParameterCodeValue.REQ_COM).then(
            coms => {
                const comDropdownOptions = coms?.map((com) => ({
                    dropdownLabel: com.parameterDtlCode,
                    tagLabel: com.parameterDtlCode,
                    value: com.parameterDtlCode,
                })) ?? []

                requisitionFormDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        comDropdownOptions: comDropdownOptions,
                    }
                }))
            }
        )

        await parameterDtlRepo.getAllParameterDtlsByParameterCode(ParameterCodeValue.REQ_MD).then(
            mds => {
                const mdDropdownOptions = mds?.map((md) => ({
                    dropdownLabel: md.parameterDtlCode,
                    tagLabel: md.parameterDtlCode,
                    value: md.parameterDtlCode,
                })) ?? []

                requisitionFormDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        mdDropdownOptions: mdDropdownOptions,
                    }
                }))
            }
        )

        await parameterDtlRepo.getAllParameterDtlsByParameterCode(ParameterCodeValue.REQ_GMD).then(
            gmds => {
                const gmdDropdownOptions = gmds?.map((gmd) => ({
                    dropdownLabel: gmd.parameterDtlCode,
                    tagLabel: gmd.parameterDtlCode,
                    value: gmd.parameterDtlCode,
                })) ?? []

                requisitionFormDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        gmdDropdownOptions: gmdDropdownOptions,
                    }
                }))
            }
        )
    }

    const getRequisitionForms = async(searchCriteria: RequisitionFormSearchCriteria) => {
        requisitionFormDispatch(prevState => {
            return {
                ...prevState,
                creditNoteHeaders: [],
                selectedCreditNoteHeaderRows: [],
            }
        })
        await requisitionFormRepo.searchRequisitionForm(searchCriteria).then((data) => {
            requisitionFormDispatch(prevState => {
                    return {
                        ...prevState,
                        requisitionForms: data,
                        searchCriteria: searchCriteria,
                        selectedRequisitionFormRows: [],
                        isAllowAutoSearch:false,
                    }
                })
        }).catch((error) => {
            return [];
        })
    }

    const getReqFormByReqFormNo = async (reqFormNo: string) => {
        requisitionFormDispatch(prevState => {
            return {
                ...prevState,
                creditNoteHeaders: [],
                selectedCreditNoteHeaderRows: [],
            }
        })
        await requisitionFormRepo.getReqFormByReqFormNo(reqFormNo).then((data) => {
            if (data) {
                requisitionFormDispatch(prevState => {
                    return {
                        ...prevState,
                        detailState: {
                            ...prevState.detailState,
                            reqFormMgrs: data.mgrs??[],
                            reqFormCusts: data.customers ?? []
                        }
                    }
                })
            }
        }).catch((error) => {
            return [];
        })
    }

    const onEdit = (currentReqForm: RequisitionFormEntity) => {
        requisitionFormDispatch(prevState => {
            return {
                ...prevState,
                masterState: {
                    ...prevState.masterState,
                    isAdd: false,
                    isRead: false,
                    isEditable: true,

                    editingEntity: {
                        ...currentReqForm,
                    }
                },
                detailState: {
                    ...prevState.detailState,
                    isRead: false
                }
            }
        })
    }

    const onResetClick = async () => {
        requisitionFormDispatch(prevState => {
            let tempReqFormEty: RequisitionFormEntity = {...EMPTY_REQUISITION_FORM_ENTITY};
            if (!prevState.masterState.isAdd) {
                tempReqFormEty = prevState.currentRequisitionForm
            }

            return {
                ...prevState,
                masterState: {
                    ...prevState.masterState,
                    editingEntity: {
                        ...tempReqFormEty,
                    },
                }
            }
        })
    }

    const onCloseClick = () => {
        requisitionFormDispatch(prevState => {
            return {
                ...prevState,
                isShowDetail: false,
                isBackFromDetail: true,
                selectedRows: [],
                isAllowAutoSearch: true,
                currentRequisitionForm: { ...EMPTY_REQUISITION_FORM_ENTITY },
                masterState: {
                    ...prevState.masterState,
                    isAdd: false,
                    isEditable: false,
                    isRead: true,
                    isTabularDataActive: true,
                    editingEntity: {...EMPTY_REQUISITION_FORM_ENTITY},
                },
                detailState: {
                    ...prevState.detailState,
                    isAdd: false,
                    isEditable: false,
                    isRead: true,
                    isTabularDataActive: true,
                    reqFormCusts: [],
                    reqFormMgrs: [],
                    currentReqFormCust: {...EMPTY_REQUISITION_FORM_CUSTOMER_ENTITY}
                }
            }
        });
    }

    const onSearchClick = () => {
        requisitionFormDispatch(prevState => {
            return {
                ...prevState,
                isShowCriteriaPanel: !prevState.isShowCriteriaPanel
            }
        }); 
    }

    const updateSelectedReqForms = (rows:any[]) => {
        requisitionFormDispatch(prevState => {
            return {
                ...prevState,
                selectedRequisitionFormRows: rows,
            }
        })
    }

    const updateSelectedReqFormCusts = async (rows: any[]) => {
        requisitionFormDispatch(prevState => {
            return {
                ...prevState,
                detailState: {
                    ...prevState.detailState,
                    selectedReqFormCusts: rows,
                }
            }
        })
    }

    const onSearchCriteriaResetClick = () => {
        requisitionFormDispatch(prevState => {
            return {
                ...prevState,
                searchCriteria: {...EMPTY_REQUISITION_FORM_SEARCH_CRITERIA}
            }
        })
    }

    const onFieldChange = (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        if (_.isArray(val)) {
            val = _.uniq(val?.map((item: any) => item.value || item.key));
        }

        return requisitionFormDispatch(prevState => {
            val = getValForSpecialField(fieldKey, val, prevState);

            return {
                ...prevState,
                masterState: {
                    ...prevState.masterState,
                    editingEntity: {
                        ...prevState.masterState.editingEntity,
                        [fieldKey]: val
                    }
                }
            }
        });
    }

    const getValForSpecialField = (fieldKey: string, fieldVal: any, prevState: RequisitionFormMaintenanceModel) => {
        if (fieldKey === "reqNature") {
            fieldVal = prevState.dynamicOptions.reqNatureDropdownOptions?.find(item => item.value === fieldVal)?.tagLabel ?? "";
        } else if (fieldKey === "reqMode") {
            fieldVal = prevState.dynamicOptions.reqModeDropdownOptions?.find(item => item.value === fieldVal)?.tagLabel ?? "";
        } 

        return fieldVal;
    }

    const onSearchGroupCheckboxChange = (e: (GroupCheckboxList | undefined)[], searchCriteria:RequisitionFormSearchCriteria, fieldName:string) => {
        let selectedValue:string[] = [];
            if (e) {
                e.forEach(function(value, index) {
                    if (value) {
                        selectedValue.push(value.key);
                    }
                });
            }
        searchCriteria = {...searchCriteria, [fieldName]:selectedValue}
        requisitionFormDispatch(prevState => ({
            ...prevState,
            searchCriteria : {
                ...prevState.searchCriteria,
                ...searchCriteria,
            }
        }));
    };

    const onCheckboxChange = (checked: boolean, fieldName: string) => {
        requisitionFormDispatch(prevState => ({
            ...prevState,
            masterState: {
                ...prevState.masterState,
                editingEntity: {
                    ...prevState.masterState.editingEntity,
                    [fieldName]: checked ? "Y" : "N"
                }
            }            
        }))
    }


    const onGroupCheckboxChange = (e: (GroupCheckboxList | undefined)[], fieldName: string) => {
        let selectedValue: string[] = [];
        if (e) {
            e.forEach(function (value, index) {
                if (value) {
                    selectedValue.push(value.key);
                }
            });
        }
        
        requisitionFormDispatch(prevState => ({
            ...prevState,
            masterState: {
                ...prevState.masterState,
                editingEntity: {
                    ...prevState.masterState.editingEntity,
                    [fieldName]: selectedValue?.join(",")??""
                }
            }
        }));
    };

    const onSearchCriteriaFieldChange = (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        if (_.isArray(val)) {
            val = _.uniq(val?.map((item: any) => item.value || item.key));
        }

        return requisitionFormDispatch(prevState => {
            if(fieldKey ==='reqMode'){
                const reqModeOptions = prevState.dynamicOptions.reqModeDropdownOptions;
                if(reqModeOptions && reqModeOptions.length > 0 && val){
                    const reqMode = reqModeOptions.find(option => option.value === val);

                    if(reqMode && reqMode.tagLabel){
                        val = reqMode.tagLabel;
                    }
                }
            } else if (fieldKey ==='reqNature') {
                const reqNatureOptions = prevState.dynamicOptions.reqNatureDropdownOptions;
                if(reqNatureOptions && reqNatureOptions.length > 0 && val){
                    const reqNature = reqNatureOptions.find(option => option.value === val);

                    if(reqNature && reqNature.tagLabel){
                        val = reqNature.tagLabel;
                    }
                }
            }            

            return {
                ...prevState,
                searchCriteria: {
                    ...prevState.searchCriteria,
                    [fieldKey]: val
                }
            }
        });
    }

    const onAdd = () => {  
        requisitionFormDispatch(prevState => {
            let currentReqForm = {...EMPTY_REQUISITION_FORM_ENTITY};
            currentReqForm.state = "NIL";
            currentReqForm.reqNature = prevState.dynamicOptions.reqNatureDropdownOptions?prevState.dynamicOptions.reqNatureDropdownOptions[0].tagLabel:"";
            currentReqForm.reqMode = prevState.dynamicOptions.reqModeDropdownOptions?prevState.dynamicOptions.reqModeDropdownOptions[0].tagLabel:"";
            currentReqForm.amount = 0;
            
            return {
                ...prevState,
                //isShowDetail: true,
                currentRequisitionForm: currentReqForm,
                masterState: {
                    ...prevState.masterState,
                    isTabularDataActive: false,
                    isAdd: true,
                    isRead: false,
                    isEditable: false,
                    isSaveClicked: false,
                    isSliderOpen: true,
                    allFormState: {},
                    editingEntity: currentReqForm,
                },
                detailState: {
                    ...prevState.detailState,
                    isTabularDataActive: false,
                    isAdd: false,
                    isRead: false,
                    isEditable: false,
                    isSliderOpen: false,
                    isSelected: false,
                    allFormState: {}
                }
            }
        });
    }

    const onReqFormCustAdd = () => {
        requisitionFormDispatch(prevState => {
            return {
                ...prevState,
                detailState: {
                    ...prevState.detailState,                    
                    isAdd: true,
                    isSliderOpen: true,
                    isEditable: false,
                    isRead: false,
                    isSaveClicked: false,
                    currentReqFormCust: {...EMPTY_REQUISITION_FORM_CUSTOMER_ENTITY}
                }
            }
        })
    }

    const onReqFormCustDelete = (selectedRows: RequisitionFormCustomerEntity[]) => {        
        requisitionFormDispatch(prevState => {
            let tempReqFormCusts = prevState.detailState.reqFormCusts;
            
            if(selectedRows && selectedRows.length > 0){
                selectedRows.forEach(item => {
                    tempReqFormCusts = tempReqFormCusts.filter(cust => !(cust.billToCompany === item.billToCompany && cust.customerCode === item.customerCode));                    
                })
            }

            return {
                ...prevState,
                detailState: {
                    ...prevState.detailState,                    
                    selectedReqFormCusts: [],
                    currentReqFormCust: {...EMPTY_REQUISITION_FORM_CUSTOMER_ENTITY},
                    reqFormCusts:tempReqFormCusts
                }
            }
        })
    }

    const onRowDoubleClick = (reqForm: RequisitionFormEntity) => {
        requisitionFormDispatch(prevState => {
            return {
                ...prevState,
                isShowCriteriaPanel: false,
                isShowDetail: true,
                currentRequisitionForm: {
                    ...reqForm
                },
                masterState: {
                    ...prevState.masterState,
                    isTabularDataActive: false,
                    isAdd: false,
                    isRead: true,
                    isEditable: true,
                    isSaveClicked: false,
                    isSliderOpen: true,
                    allFormState: {}
                },
                detailState: {
                    ...prevState.detailState,
                    isTabularDataActive: false,
                    isAdd: false,
                    isRead: true,
                    isEditable: false,
                    isSliderOpen: false,
                    isSelected: false,
                    allFormState: {}
                }
            }
        })
    }
    
    const onShowLoading = () => {
        requisitionFormDispatch(prevState => {
            return {
                ...prevState,
                isLoading: true,
            }
        })
    }

    const onHideLoading = () => {
        requisitionFormDispatch(prevState => {
            return {
                ...prevState,
                isLoading: false,
            }
        })
    }
    const onPageInit = (loginUser: string) =>{
        requisitionFormDispatch(prevState => {
            
            return { ...prevState,searchCriteria: {...prevState.searchCriteria,createdBy: loginUser}};
        })
    }

    const onDelete = async (creHdrIds: number[]) => {        
        return await requisitionFormRepo.deleteReqFormEntity(creHdrIds);
    }

    const onReqFormCustSaveClicked = () => {
        requisitionFormDispatch(prevState => {
            return {
                ...prevState,
                detailState: {
                    ...prevState.detailState,
                    isSaveClicked: true,
                    allFormState: {},
                }
            }
        })
    }

    const onReqFormMgrFieldChange = async (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        if (_.isArray(val)) {
            val = _.uniq(val?.map((item: any) => item.value || item.key));
        }

        requisitionFormDispatch(prevState => {
            let tempReqFormMgrs = prevState.detailState.reqFormMgrs;
            let editingEntity = prevState.masterState.editingEntity;

            if(!tempReqFormMgrs){
                tempReqFormMgrs = [];
            }

            let tempMgrPosition: string = "";
            if(fieldKey === "mgrIcName"){                
                tempMgrPosition = "MGR_IC";
            } else if(fieldKey === "mgrAcName"){
                tempMgrPosition = "MGR_AC";
            } else if(fieldKey === "finName"){
                tempMgrPosition = "FIN";
            } else if(fieldKey === "comName"){
                tempMgrPosition = "COM";
            } else if(fieldKey === "mdName"){
                tempMgrPosition = "MD";
            } else if(fieldKey === "gmdName"){
                tempMgrPosition = "GMD";
            }

            let tempReqFormMgr = tempReqFormMgrs.find(mgr => mgr.mgrPosition === tempMgrPosition);
            if(!tempReqFormMgr){
                tempReqFormMgr = {...EMPTY_REQUISITION_FORM_MGR_ENTITY};

                tempReqFormMgr.mgrPosition = tempMgrPosition;
                tempReqFormMgrs.push(tempReqFormMgr);
            } 
            tempReqFormMgr.mgrName = val;

            if(tempReqFormMgrs && tempReqFormMgrs.length > 0){
                editingEntity.mgrs = tempReqFormMgrs
            }            

            return {
                ...prevState,
                masterState:{
                    ...prevState.masterState,
                    editingEntity: editingEntity
                },
                detailState: {
                    ...prevState.detailState,
                    reqFormMgrs: tempReqFormMgrs,
                    allFormState: {
                        ...prevState.detailState.allFormState,
                        [fieldKey]: '',
                    },
                }
            }
        })
    }

    const onReqFormMgrDateTimeChange = (fieldName: string, val:any) => {
        requisitionFormDispatch(prevState => {
            let tempReqFormMgrs = prevState.detailState.reqFormMgrs;
            let editingEntity = prevState.masterState.editingEntity;
            if(!tempReqFormMgrs){
                tempReqFormMgrs = [];
            }

            let tempMgrPosition: string = "";
            if(fieldName === "mgrIcConfirmDatetime"){                
                tempMgrPosition = "MGR_IC";
            } else if(fieldName === "mgrAcConfirmDatetime"){
                tempMgrPosition = "MGR_AC";
            } else if(fieldName === "finConfirmDatetime"){
                tempMgrPosition = "FIN";
            } else if(fieldName === "comConfirmDatetime"){
                tempMgrPosition = "COM";
            } else if(fieldName === "mdConfirmDatetime"){
                tempMgrPosition = "MD";
            } else if(fieldName === "gmdConfirmDatetime"){
                tempMgrPosition = "GMD";
            }

            let tempReqFormMgr = tempReqFormMgrs.find(mgr => mgr.mgrPosition === tempMgrPosition);
            if(!tempReqFormMgr){
                tempReqFormMgr = {...EMPTY_REQUISITION_FORM_MGR_ENTITY};

                tempReqFormMgr.mgrPosition = tempMgrPosition;
            }
            tempReqFormMgr.confirmDatetime = val;
            if(tempReqFormMgrs && tempReqFormMgrs.length > 0){
                editingEntity.mgrs = tempReqFormMgrs
            } 

            return {
                ...prevState,
                masterState:{
                    ...prevState.masterState,
                    editingEntity: editingEntity
                },
                detailState: {
                    ...prevState.detailState,
                    reqFormMgrs: tempReqFormMgrs,
                    allFormState: {
                        ...prevState.detailState.allFormState,
                        [fieldName]: '',
                    },
                }
            }
        })
    }

    /*const onReqFormCustCancel = () => {
        requisitionFormDispatch(prevState => {
            return {
                ...prevState,
                detailState: {
                    ...prevState.detailState,
                    currentReqFormCust: EMPTY_REQUISITION_FORM_CUSTOMER_ENTITY,
                    isAdd: false,
                    isEditable: false,
                    //isRead: false,
                    isSliderOpen: false,
                    allFormState: {}
                }
            }
        })
    }*/

    const onReqFormCustFieldChange = async (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        if (_.isArray(val)) {
            val = _.uniq(val?.map((item: any) => item.value || item.key));
        }

        requisitionFormDispatch(prevState => {
            return {
                ...prevState,
                detailState: {
                    ...prevState.detailState,
                    currentReqFormCust: {
                        ...prevState.detailState.currentReqFormCust,
                        [fieldKey]: val
                    },
                    allFormState: {
                        ...prevState.detailState.allFormState,
                        [fieldKey]: '',
                    },
                }
            }
        })
    }

    const onReqFormCustCancel = () => {
        requisitionFormDispatch(prevState => {
            return {
                ...prevState,
                detailState: {
                    ...prevState.detailState,
                    currentReqFormCust: {...EMPTY_REQUISITION_FORM_CUSTOMER_ENTITY},
                    isAdd: false,
                    isEditable: false,
                    //isRead: false,
                    isSliderOpen: false,
                    allFormState: {}
                }
            }
        })
    }

    const onSaveClicked = () => {
        requisitionFormDispatch(prevState => {
            return {
                ...prevState,
                masterState: {
                    ...prevState.masterState,
                    isSaveClicked: true,
                    allFormState: {},
                }
            }
        })
    }

    const onSave = async (currentReqFormEntity: RequisitionFormEntity, isAdd: boolean) => {
        let valResult = await Validation(requisitionFormValidationSchema).ValidateFormOnly(currentReqFormEntity);
        
        if (valResult) {
            let validatedResult: { [x: string]: string } = {};
            if (valResult) {
                validatedResult = { ...validatedResult, ...valResult, mandatoryCheckFail: 'Please input the missing value.' };
            }

            requisitionFormDispatch(prevState => {
                return {
                    ...prevState,
                    masterState: {
                        ...prevState.masterState,
                        allFormState: {
                            ...validatedResult
                        },

                    }
                }
            });
            //console.log(validatedTariffResult);
            return validatedResult;
        } else {

            let res;
            if (currentReqFormEntity.id && currentReqFormEntity.id !== 0) {
                res = await requisitionFormRepo.updateReqFormEntity(currentReqFormEntity);
            } else {
                res = await requisitionFormRepo.addReqFormEntity(currentReqFormEntity);
            }

            if (res.success) {
                onCloseClick();
            } else {
                return res.data;
            }
        }
    }

    const onTempSaveReqFormCust = async (currentReqFormCust: RequisitionFormCustomerEntity) => {
        const valResult = await Validation(requisitionFormCustValidationSchema).ValidateFormOnly(currentReqFormCust);

        //let msg = 'Error when save data.'        
        let validatedResult: { [x: string]: string } = {};
        if (valResult) {
            validatedResult = { ...validatedResult, ...valResult, mandatoryCheckFail: 'Please input the missing value.' };
            requisitionFormDispatch(prevState => {
                return {
                    ...prevState,
                    detailState: {
                        ...prevState.detailState,
                        allFormState: {
                            ...validatedResult
                        },
                    }
                }
            });
            return validatedResult;
        } else {
            return requisitionFormDispatch(prevState => {
                let tempTableData = prevState.detailState.reqFormCusts;
                let requisitionFormEty = prevState.masterState.editingEntity;

                if (!tempTableData) {
                    tempTableData = [];
                }

                currentReqFormCust.reqFormId = requisitionFormEty.id;
                tempTableData.push(currentReqFormCust);
                requisitionFormEty.customers = tempTableData;

                return {
                    ...prevState,
                    //currentRequisitionForm: requisitionFormEty,
                    masterState: {
                        ...prevState.masterState,
                        editingEntity: requisitionFormEty
                    },
                    detailState: {
                        ...prevState.detailState,
                        reqFormCusts: tempTableData,                        
                        selectedReqFormCusts: [],
                        currentReqFormCust: {...EMPTY_REQUISITION_FORM_CUSTOMER_ENTITY},
                        isShowDetailInfo: false,
                        isAdd: false,
                        isEditable: false,
                        isSliderOpen: false,
                    }
                }
            })
        }
    }

    return {
        loadDropdownOption: loadDropdownOption,
        loadDetailDropdownOption: loadDetailDropdownOption,
        getRequisitionForms: getRequisitionForms,
        getReqFormByReqFormNo: getReqFormByReqFormNo,
        onEdit: onEdit,
        onResetClick: onResetClick,
        onCloseClick: onCloseClick,
        onSearchClick: onSearchClick,
        updateSelectedReqForms: updateSelectedReqForms,
        updateSelectedReqFormCusts: updateSelectedReqFormCusts,
        onSearchCriteriaResetClick: onSearchCriteriaResetClick,
        onSearchCriteriaFieldChange: onSearchCriteriaFieldChange,
        onSearchGroupCheckboxChange: onSearchGroupCheckboxChange,
        onFieldChange: onFieldChange,
        onCheckboxChange: onCheckboxChange,
        onGroupCheckboxChange: onGroupCheckboxChange,
        onAdd: onAdd,
        onReqFormCustAdd: onReqFormCustAdd,
        onReqFormCustDelete: onReqFormCustDelete,
        onRowDoubleClick: onRowDoubleClick,
        onShowLoading: onShowLoading,
        onHideLoading: onHideLoading,
        onPageInit: onPageInit,
        onDelete: onDelete, 
        onReqFormMgrFieldChange: onReqFormMgrFieldChange,
        onReqFormMgrDateTimeChange: onReqFormMgrDateTimeChange,
        onReqFormCustFieldChange: onReqFormCustFieldChange,
        onReqFormCustSaveClicked: onReqFormCustSaveClicked,
        onReqFormCustCancel: onReqFormCustCancel,
        onTempSaveReqFormCust: onTempSaveReqFormCust,
        onSaveClicked: onSaveClicked,
        onSave: onSave
    }
}