import React from "react";
import RequisitionFormMaintenance from "./RequisitionFormMaintenance";
import { useRequisitionFormMaintenanceTracked } from "presentation/store/RequisitionForm/RequisitionFormMaintenanceProvider";
import { SliderPanel } from "veronica-ui-component/dist/component/core";
import RequisitionFormEditPanel from "./RequisitionFormEditPanel";
import { RequisitionFormHeaderFormPanel } from "./Form/RequisitionFormHeaderFormPanel";

export const RequisitionFormMasterView: React.FC = () => {
     const [requisitionFormState] = useRequisitionFormMaintenanceTracked();
     const {isAdd, isEditable, isSliderOpen} = requisitionFormState.masterState;
   return <>
        <div className="main-comp-wrapper">            
            <RequisitionFormMaintenance/>            
            {
                ((isAdd || isEditable) &&
                <SliderPanel
                    isOpen={isSliderOpen}
                    leftSectionWidth={"42%"}
                    rightSectionWidth={"58%"}
                    leftChildren={<RequisitionFormHeaderFormPanel/>}
                    rightChildren={<RequisitionFormEditPanel/>}
                />
                )
            }
        </div>

    </>
}
