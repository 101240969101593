import _ from "lodash";

export const ReqModeDroOpts = () => {
    const MODE  = {
        AN: "Credit Note(AN)",
        TT: "Telegraphic Transfer(TT)",
        WN: "Waiver(WN)"
    };   

    const getReqModeModel = () => {
        let ret = MODE;

        return [
            ...(_.map(
                _.toPairs(ret), ([key, value]) => (
                    { dropdownLabel: value, tagLabel: key, value: value }
                )
            ) ?? [])];
    }

    return {
        getReqModeModel
    }
}