/* eslint-disable import/extensions */
import { MultiRowSelectionOptions, SingleRowSelectionOptions } from 'ag-grid-community/dist/types/core/entities/gridOptions';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import { CellClassRules, GridOptions, LicenseManager, RowSelectionOptions, type SideBarDef, type StatusPanelDef } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import type { WorkspaceModel } from 'presentation/model/workspaceModel';
import { SystemPreferenceProvider } from 'presentation/store/SystemPreference/SystemPreferenceProvider';
import { NbisFilterList } from 'presentation/view/components/NbisFilterList';
import { getColumnFilterByDataType, initColumnBodyTemplate, initFilterTemplate, onCellEditingStopped, onRowEditingStarted, onRowEditingStopped, sortingComparator } from 'presentation/view/components/TableWrapper/NbisTableAssist';
import { DeletePreferenceModal } from 'presentation/view/components/TableWrapper/Preference/DeletePreferenceModal';
import PreferenceMenu from 'presentation/view/components/TableWrapper/Preference/PreferenceMenu';
import { SaveNewPreferenceModal } from 'presentation/view/components/TableWrapper/Preference/SaveNewPreferenceModal';
import { forwardRef, memo, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { IconButton } from 'veronica-ui-component/dist/component/core/IconButtons/IconButtons';
import { InputField } from 'veronica-ui-component/dist/component/core/InputField/InputField';
import { GridStyles, StyledHeaderLabel, TableHeader, TableQuickSearch } from '../styled/NbisTable.styled';
import Editors from './Editors';
import { defaultNbisTableProps, NbisTableProps } from './NbisTableConstant';

LicenseManager.setLicenseKey("Using_this_{AG_Grid}_Enterprise_key_{AG-071655}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Hutchison_Ports_nGen_Services_Limited}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{nBIS_Modernisation}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{nBIS_Modernisation}_need_to_be_licensed___{nBIS_Modernisation}_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{14_December_2025}____[v3]_[01]_MTc2NTY3MDQwMDAwMA==07e6c4a843d56bfbd1786ac39f229ec8");

const NbisTable = forwardRef((props: NbisTableProps, ref: React.Ref<any>) => {
    const combineProps = useMemo(() => ({ ...defaultNbisTableProps, ...props }), [props]);
    const { id = 'tableId', editMode, showPaginator, customHeight, selectionMode, row, showHeaderIcons, onRowDoubleClick,
        showReloadIcon, showUploadIcon, showAddIcon, addButtonId, onAddClick, headerLabel, gridHeight,
        isRowChange, isRowDrag, showSidebar, columnSettingIconList, showQuickSearch, quickSearchLabel, quickSearchType,
        showStatusBar, autoMarginLeft, ...rest } = combineProps;

    const [data, setData] = useState<any[]>([]);
    const [columns, setColumns] = useState<any[]>();
    const [quickSearchText, setQuickSearchText] = useState('');
    const rowHeight = 32;
    const headerHeight = 36;
    const [initialState, setInitialState] = useState<boolean>(false);
    const [prevProps, setPrevProps] = useState<NbisTableProps>();
    const [isGridReady, setIsGridReady] = useState<boolean>(false);
    // const [systemPreferenceState] = useSystemPreferenceTracked();
    // const systemRreferenceVM = useSystemPreferenceVM();
    const [isShowSaveNewConfirmModal, setIsShowSaveNewConfirmModal] = useState<boolean>(false);
    const [isShowDeleteConfirmModal, setIsShowDeleteConfirmModal] = useState<boolean>(false);

    const workspaceState = JSON.parse(sessionStorage.getItem('workspace-state') ?? "") as WorkspaceModel;

    /* istanbul ignore next */
    const gridRef: any = useRef<any>(null);
    const tableRef: any = useRef<any>(null);

    useImperativeHandle(ref, () => ({
        gridRef: gridRef,
    }));

    const dragColumnObj = useCallback((id: number) => ({
        id: id,
        colId: 'dragCol',
        filter: false,
        editable: false,
        resizable: false,
        headerName: '',
        rowDrag: true,
        width: '24px',
        maxWidth: 24,
        hide: !combineProps.isRowDrag || combineProps.showPaginator,
        suppressColumnsToolPanel: true,
    }), [combineProps.isRowDrag, combineProps.showPaginator]);

    const selectionColumnObj = useCallback((id: number) => ({
        id: id,
        colId: 'selectionCol',
        filter: false,
        editable: false,
        resizable: false,
        headerName: '',
        width: '24px',
        headerCheckboxSelection: true,
        checkboxSelection: true,
        hide: !combineProps.selectionMode,
        showDisabledCheckboxes: true,
        maxWidth: 24,
        suppressColumnsToolPanel: true,
        isRowSelectable: (node: any) => isRowSelectable(node)
    }), [combineProps.selectionMode]);

    const actionColumnObj = useCallback((id: number) => ({
        id: id,
        colId: 'action',
        filter: false,
        editable: false,
        resizable: false,
        suppressColumnsToolPanel: true
    }), [])

    const isRowSelectable = (params: any) => {
        return params?.data?.flag_disabled ? false : true;
    };

    const cellClassRules = useMemo<CellClassRules>(() => ({
        "im-table-wrapper-move-cursor": (params) => {
            return params?.data?.flag_showCutCursor === true;
        }
    }), []);

    const rowSelection = useCallback((): RowSelectionOptions | undefined => {
        if (combineProps.selectionMode || combineProps.rowSelection === 'multiple') {
            const multiRowSelection: MultiRowSelectionOptions = {
                mode: "multiRow",
                enableClickSelection: !isRowChange,
                checkboxes: false,
                headerCheckbox: false,
            };
            return multiRowSelection;
        } else {
            const singleRowSelection: SingleRowSelectionOptions = {
                mode: 'singleRow',
                checkboxes: false,
                enableClickSelection: !isRowChange,
            }
            return singleRowSelection;
        }
    }, [isRowChange, combineProps.rowSelection, combineProps.selectionMode]);

    /* istanbul ignore next */
    const onHeaderActionButtonHandler = (item: any) => {
        return item.id && props[item.id] && props[item.id](props);
    };

    /* istanbul ignore next */
    const columnBodyTemplate = useCallback((params: any, props: any) => {
        return initColumnBodyTemplate(params, props);
    }, []);

    /* istanbul ignore next */
    const filterTemplate = useCallback((params: any) => {
        return initFilterTemplate(params);
    }, []);

    // const restoreGridState = useCallback(async () => {
    //     if (gridRef?.current && gridRef?.current?.api) {
    //         gridRef.current!.api.setFilterModel(null);

    //         gridRef?.current?.api?.setGridOption("columnDefs", columns);
    //         if (systemPreferenceState?.activeSystemPreference) {
    //             gridRef.current?.applyColumnState({
    //                 state: systemPreferenceState.activeSystemPreference.contentForScrene.columnState,
    //                 applyOrder: true,
    //             });
    //             gridRef.current?.api.setFilterModel(
    //                 systemPreferenceState.activeSystemPreference.contentForScrene.filterModel
    //             );
    //         }
    //     }
    // }, [columns, systemPreferenceState?.activeSystemPreference]);

    // const saveGridState = useCallback(async () => {
    //     if (systemPreferenceState?.activeSystemPreference) {
    //         systemRreferenceVM.setActiveSystemPreference({
    //             ...systemPreferenceState.activeSystemPreference,
    //             contentForScrene: {
    //                 columnState: gridRef.current?.api.getColumnState(),
    //                 filterModel: gridRef.current?.api.getFilterModel(),
    //             },
    //             content: JSON.stringify({
    //                 columnState: gridRef.current?.api.getColumnState(),
    //                 filterModel: gridRef.current?.api.getFilterModel(),
    //             }),
    //         })
    //     }
    // }, [systemPreferenceState?.activeSystemPreference, systemRreferenceVM]);

    const gridOptions = useCallback((): GridOptions | undefined => {
        return {
            suppressMoveWhenColumnDragging: true,
            suppressDragLeaveHidesColumns: true,
            // onColumnResized: () => saveGridState(),
            // onSortChanged: () => saveGridState(),
            // onColumnMoved: () => saveGridState(),
            // onFilterChanged: () => saveGridState(),
        }
    }, []);

    // const autoSizeStrategy = useMemo<
    //     | SizeColumnsToFitGridStrategy
    //     | SizeColumnsToFitProvidedWidthStrategy
    //     | SizeColumnsToContentStrategy
    // >(() => {
    //     return {
    //         type: "fitCellContents",
    //         skipHeader: false
    //     };
    // }, []);

    const statusBar = useMemo<{
        statusPanels: StatusPanelDef[];
    }>(() => {
        return {
            statusPanels: [
                // { statusPanel: 'agTotalAndFilteredRowCountComponent' },
                { statusPanel: 'agTotalRowCountComponent', align: 'left' },
                { statusPanel: 'agFilteredRowCountComponent', align: 'left' },
                { statusPanel: 'agSelectedRowCountComponent', align: 'left' },
                // { statusPanel: 'agAggregationComponent' }
            ]
        };
    }, []);

    const showSaveModel = useCallback(() => {
        setIsShowSaveNewConfirmModal(!isShowSaveNewConfirmModal);
    }, [isShowSaveNewConfirmModal]);

    const showDeleteModel = useCallback(() => {
        setIsShowDeleteConfirmModal(!isShowDeleteConfirmModal);
    }, [isShowDeleteConfirmModal]);

    const memoPreferenceMenu = useMemo(() => {
        return (
            <PreferenceMenu
                entryPoint={props.entrypoint ?? workspaceState.entrypoint ?? ""}
                gridRef={gridRef}
                tableId={id}
                showDeleteModal={showDeleteModel}
                showSaveModal={showSaveModel}
            />
        );
    }, [props.entrypoint, workspaceState.entrypoint, id, showDeleteModel, showSaveModel]);

    // const getGridColumns = useCallback(() => {
    //     return <FilterList
    //         colDef={gridRef?.current?.api?.getColumns()?.map((col: any) => ({ columnKey: col.colId, columnName: col.headerName, visible: !col.hide }))}
    //         onMoveToIndex={handleColumnMove}
    //         onVisibilityToggle={handleColumnShowHide} />
    // }, [gridRef]);

    const onCellKeyDown = useCallback((e: KeyboardEvent) => {
        const keyCode = e?.key;
        const KEY_UP = 'ArrowUp';
        const KEY_DOWN = 'ArrowDown';
        const selectedNode = gridRef.current?.api?.getSelectedNodes()[0];
        const selectedRowIndex = selectedNode?.rowIndex;

        if (gridRef.current) {
            if (keyCode === KEY_UP || keyCode === KEY_DOWN) {
                e.preventDefault();
                if (selectedNode) {
                    const newRowIndex = keyCode === KEY_UP ? selectedRowIndex - 1 : selectedRowIndex + 1;
                    const newRowNode = gridRef.current?.api?.getRowNode(newRowIndex);
                    if (newRowNode) {
                        newRowNode?.setSelected(true);
                        gridRef.current?.api?.ensureNodeVisible(newRowNode);
                    }
                }
            }
        }
    }, []);

    const onColumnSettingOutsideClick = useCallback((event: MouseEvent) => {
        let api = gridRef.current.api;
        let isSidebarOpen = api?.isSideBarVisible();
        if (isSidebarOpen) {
            let sideBarRef = tableRef.current.querySelector('.ag-side-bar').contains(event.target);
            let settingButtonRef = tableRef.current.querySelector('#setting-button').contains(event.target);
            if (!sideBarRef && !settingButtonRef) {
                api.closeToolPanel();
                api.setSideBarVisible(false);
            }
        }
    }, []);

    /* istanbul ignore next */
    const exportExcel = () => {
        //Exporting Table to CSV with selectionMode set to true or showActionColumn set to true adds a blank column to the CSV

        const allColumns = gridRef?.current?.api?.getColumnDefs();
        const excludeColumns = ["dragCol", "selectionCol", "action"];
        const exportColumns = allColumns
            .filter((column: any) => !excludeColumns.includes(column.colId))
            .map((column: any) => column.colId);

        gridRef?.current?.api?.exportDataAsCsv({
            columnKeys: exportColumns
        });
    };

    /* istanbul ignore next */
    const onReload = () => {
        let clonedData = combineProps.data && JSON.parse(JSON.stringify(combineProps.data));
        setData(clonedData);

        gridRef?.current?.api?.redrawRows();
        gridRef.current?.api?.setFilterModel(null);

        let updatedCols = combineProps.columns;

        if (combineProps.showActionColumn) {
            const actionColExists = combineProps.columns?.filter(item => item.colId === 'action')[0];

            if (!actionColExists) {
                updatedCols = [...combineProps.columns || [], {
                    id: (combineProps.columns || [])?.length + 1,
                    colId: 'action',
                    filter: true,
                    editable: false,
                    resizable: false,
                    suppressColumnsToolPanel: true,
                }];
            }
            // updatedCols?.unshift(selectionColumnObj(), dragColumnObj());
        }
        gridRef?.current?.api?.setColumnDefs(updatedCols);
    };

    /* istanbul ignore next */
    const onRowClicked = (params: any) => {
        let newData: any = {};
        if (!combineProps.isScrollHighlighted) {
            newData = data?.map((item: any, i: any) => {
                if (!(params.rowIndex === i) && item?.flag_edit) {
                    item.flag_edit = false;
                    let editingCells = params?.api?.getEditingCells();
                    let isCurrentRowEditing = editingCells.some((cell: any) => {
                        return cell.rowIndex === params.node.rowIndex;
                    });
                    if (!isCurrentRowEditing) {
                        gridRef?.current?.api?.redrawRows();
                    }
                }
                return item;
            });
            setData(newData);

            combineProps.onRowClick && combineProps.onRowClick(params, combineProps.id);
        } else {
            return combineProps.onRowClick && combineProps.onRowClick(params, combineProps.id);
        }
    };

    /* istanbul ignore next */
    const defaultColDef = useMemo(() => (
        {
            wrapHeaderText: false,
            autoHeaderHeight: true,
            resizable: true,
            sortable: true,
            filter: true,
            filterParams: (params: any) => filterTemplate(params),
            // suppressSizeToFit: true,
        }
    ), [filterTemplate]);

    /* istanbul ignore next */
    const onGridReady = useCallback((params: any) => {
        //set data source for server side
        if (combineProps.rowModelType === 'serverSide' && combineProps.dataSource) {
            params.api?.setServerSideDatasource(combineProps.dataSource());
            // loadGridState();
        }

        //scroll the editing row to top
        data?.map((item: any, i: any) => {
            if (item?.flag_edit && item.flag_edit === true) {
                return gridRef?.current?.api?.ensureIndexVisible(i, 'top');
            }
            return item;
        });

        // params?.api?.setGridOption("rowData", combineProps.data ?? []);
        params?.api?.autoSizeColumns(['action']);
        // restoreGridState();
    }, [combineProps, data]);

    /* istanbul ignore next */
    const getRowClass = (params: any) => {
        if (!params.data) return;

        if (params.data.flag_edit && params.data.flag_edit === true) {
            return 'edited-row';
        }
        if (params.data.flag_disabled && params.data.flag_disabled === true) {
            return 'disabled-row';
        }
    };

    /* istanbul ignore next */
    const postProcessPopup = (params: any) => {
        const dialog = params.ePopup;
        if (params?.type === 'columnMenu') {
            const header = document.querySelector('.ag-header') as HTMLElement;
            const margin = parseInt(header.style.height, 10) - parseInt(dialog.style.top, 10);
            dialog.style.marginTop = `${margin}px`;
            dialog.style.display = 'block';
        }
    };

    /* istanbul ignore next */
    const onRowDragEnd = (event: any) => {
        const { node, overIndex } = event;
        const updatedRowData = [...data];
        const movedRow = node.data;
        const movedIndex = updatedRowData.indexOf(movedRow);
        updatedRowData.splice(movedIndex, 1);
        updatedRowData.splice(overIndex, 0, movedRow);
        setData(updatedRowData);
        combineProps.onRowDragged && combineProps.onRowDragged(event, updatedRowData, movedIndex, overIndex)!;
    };

    const onColumnSettingClick = () => {
        let isSidebarOpen = gridRef.current?.api?.isSideBarVisible();
        if (!isSidebarOpen) {
            initSiderBar();
        }

        gridRef.current?.api?.setSideBarVisible(!isSidebarOpen);

        if (isSidebarOpen) {
            gridRef.current?.api?.closeToolPanel();
        } else {
            gridRef.current?.api?.openToolPanel('columns');
        }
    };

    const handleColumnMove = (columnKey: string, toIndex: number) => {
        gridRef.current?.api.moveColumn(columnKey, toIndex);
    };

    const handleColumnShowHide = (columnKeys: string[], isVisible: boolean) => {
        columnKeys.forEach(columnKey => {
            gridRef.current?.api.setColumnVisible(columnKey, isVisible);
        });
    };

    const handleQuickSearch = (searchText: string = '') => {
        if (!combineProps.quickSearch || !combineProps.showQuickSearch) return null;

        const { quickSearch, rowModelType } = combineProps;
        const { quickSearchType } = quickSearch;

        let sText = searchText || '';
        if (quickSearchType === 'clickSearch') sText = quickSearchText;

        // Make logic to search item
        if (rowModelType === 'clientSide') gridRef.current.api.setQuickFilter(sText);
    };

    const handleQuickTextChnageSearch = (e: any) => {
        if (!combineProps.quickSearch || !combineProps.showQuickSearch) return null;

        const { quickSearchType } = combineProps.quickSearch;
        setQuickSearchText(e.target.value);

        if (quickSearchType === 'typeSearch') {
            handleQuickSearch(e.target.value);
        }
    };

    const getToolPanelColumns = () => {
        const columnStates = gridRef?.current?.api?.getColumnState();
        const columns = gridRef?.current?.api?.getColumns();
        return columnStates?.map((colState: any) => {
            const column = columns?.find((col: any) => col.colId === colState.colId);
            return {
                columnKey: colState.colId,
                columnName: column.colDef.headerName,
                visible: !colState.hide,
                isDraggable: !colState.pinned,
            }
        })
    }

    const initSiderBar = useCallback(() => {
        const siderBarDef: SideBarDef = {
            toolPanels: [combineProps.isNewColumnSetting ? {
                id: 'columns',
                labelDefault: 'Column Settings',
                labelKey: 'columns',
                iconKey: 'columns',
                toolPanel: () => <NbisFilterList
                    colDef={getToolPanelColumns()}
                    onMoveToIndex={handleColumnMove}
                    onVisibilityToggle={handleColumnShowHide} />,
            } : {
                id: 'columns',
                labelDefault: 'Column Settings',
                labelKey: 'columns',
                iconKey: 'columns',
                toolPanel: 'agColumnsToolPanel',
                toolPanelParams: {
                    suppressRowGroups: false,
                    suppressValues: false,
                    suppressPivotMode: false,
                    suppressColumnFilter: true,
                    suppressColumnSelectAll: true,
                    suppressColumnExpandAll: true,
                },
            }],
            hiddenByDefault: true,
        }
        gridRef.current?.api?.setGridOption("sideBar", siderBarDef);
    }, [combineProps.isNewColumnSetting, gridRef]);

    const handlePivotMode = () => {
        const current = gridRef.current;

        const isPivotMode = current.api.isPivotMode();
        if (isPivotMode === false) document.querySelector('.ag-table.ag-grid-width')?.setAttribute('class', 'ag-table ag-grid-width pivot-mode-off');
        else document.querySelector('.ag-table.ag-grid-width')?.setAttribute('class', 'ag-table ag-grid-width');
    };

    const getQuickSearchBar = () => {
        if (!combineProps.quickSearch || !combineProps.showQuickSearch) return null;

        const { quickSearchLabel, quickSearchType, width } = combineProps.quickSearch;

        return <TableQuickSearch style={{ width: width ? width : '20%' }}>
            <InputField type={'text'} maxLength={99} value={quickSearchText} label={quickSearchLabel} onChange={handleQuickTextChnageSearch} />
            {(quickSearchType === 'clickSearch') && <IconButton className='Icon-search' fileName="Icon-search" size="medium" toolTipArrow={true} onClick={() => handleQuickSearch('')} />}
        </TableQuickSearch>;
    };

    /* istanbul ignore next */
    useEffect(() => {
        if (!initialState) return;

        document.addEventListener('keydown', onCellKeyDown);
        document.addEventListener('mousedown', onColumnSettingOutsideClick);

        setInitialState(true);
        return (() => {
            document.removeEventListener('keydown', onCellKeyDown);
            document.removeEventListener('mousedown', onColumnSettingOutsideClick);
        })
    }, [initialState, onCellKeyDown, onColumnSettingOutsideClick]);

    const getAllColumns = useCallback((colDefs: any[], props: NbisTableProps) => {
        const filteredCols = colDefs?.filter((item: any) => item.colId !== 'action' && item.colId !== 'dragCol' && item.colId !== 'selectionCol');
        //make sure the action, drag, selection columns
        filteredCols?.unshift(selectionColumnObj(1), dragColumnObj(2));
        filteredCols?.push(actionColumnObj(filteredCols.length + 3));

        const updatedColDefs = filteredCols?.map((item, index) => {
            if (item.colId === 'action') {
                item.hide = !combineProps.showActionColumn;
                item.editable = false;
            } else if (item?.colId === 'dragCol') {
                item.hide = !combineProps.isRowDrag || combineProps.showPaginator;
                item.editable = false;
            } else if (item?.colId === 'selectionCol') {
                item.hide = !(combineProps.selectionMode || combineProps.rowSelection === 'multiple');
                item.pinned = "left";
                item.editable = false;
            } else {
                item.editable = combineProps.editable;
                item.checkboxSelection = false;
                item.headerCheckboxSelection = false;
                item.pinned = undefined;
            }
            if (index === 2 && (combineProps.selectionMode || combineProps.rowSelection === 'multiple')) {
                item.pinned = "left";
            }

            //if user enable the group, then set all columns allows to be grouped
            if (props.rowGroupPanelShow) {
                item.enableRowGroup = true;
            }

            // if (props.pivotMode) {
            //     item.enablePivot = true;
            //     item.pivot = true;
            // }

            item.cellClassRules = cellClassRules;
            // item.filterParams = (params: any) => filterTemplate(params);
            item.cellRenderer = item.cellRenderer || ((params: any) => {
                return columnBodyTemplate(params, { ...props });
            });
            return item;
        });

        return updatedColDefs;
    }, [actionColumnObj, cellClassRules, columnBodyTemplate, combineProps.editable, combineProps.isRowDrag, combineProps.rowSelection,
        combineProps.selectionMode, combineProps.showActionColumn, combineProps.showPaginator, dragColumnObj, selectionColumnObj]);

    useEffect(() => {
        if (JSON.stringify(prevProps?.columns) === JSON.stringify(combineProps.columns)) return;

        const updatedCols = combineProps.columns?.map((item, index) => {
            item.dataType = item.dataType || 'text';
            item.headerName = item.header || item.headerName;
            item.filter = item.filter && getColumnFilterByDataType(item.dataType);
            item.sortable = item.sortable !== undefined ? item.sortable : true;
            item.comparator = item.dataType !== 'boolean' && sortingComparator;
            item.editable = combineProps.editable;
            item.cellRenderer = item.cellRenderer || ((params: any) => {
                return columnBodyTemplate(params, { ...props });
            });
            item.tempWidth = item.tempWidth ?? item.width;
            item.width = item.tempWidth ? item.tempWidth + 50 : 200;
            // item.filterParams = (params: any) => filterTemplate(params);
            item.cellEditor = item.cellEditor || Editors;
            item.menuTabs = ['filterMenuTab'];
            item.colId = `${item?.id || item?.colId || index + 1}`;
            if ((item.dataType !== 'color' && item.dataType !== 'boolean' && item.dataType !== 'dateTime' && item.dataType !== 'date'
                && item.colId !== 'frontaction' && item.colId !== 'action') && !item.cellRenderer) {
                item.cellRenderer = (params: any) => {
                    const v = params?.value?.toString()?.trim()!;
                    if (!v) return null;
                    if (quickSearchText === '') return v;
                    const len: number = quickSearchText?.length;
                    const index: number = v?.toString()?.toLowerCase()?.indexOf(quickSearchText?.toString()?.toLowerCase());
                    return (
                        <>
                            <span>{v?.toString()?.slice(0, index)}</span>
                            <span>{v?.toString()?.slice(index, index + len)}</span>
                            <span>{v?.toString()?.slice(index + len)}</span>
                        </>
                    );
                };
            }

            return item;
        });

        const updatedColDefs = getAllColumns(updatedCols ?? [], combineProps);

        setPrevProps({ ...prevProps, columns: combineProps.columns });
        setColumns([...updatedColDefs ?? []]);
        setIsGridReady(true);
    }, [columnBodyTemplate, combineProps, getAllColumns, prevProps, props, quickSearchText]);

    useEffect(() => {
        if (combineProps.data && combineProps.isScrollHighlighted) {
            setTimeout(() => {
                combineProps.data?.map((item: any, i: any) => {
                    if (item?.flag_edit && item.flag_edit === true) {
                        gridRef.current?.api?.ensureIndexVisible(i, 'top');
                    }
                    return item;
                });
            });
        }

        if (JSON.stringify(data) !== JSON.stringify(combineProps.data)) {
            setData(combineProps.data ?? []);
        }
    }, [data, combineProps.data, combineProps.isScrollHighlighted]);

    /* istanbul ignore next */
    useEffect(() => {
        if (isGridReady && (
            prevProps?.showActionColumn !== combineProps.showActionColumn ||
            prevProps?.gridHeight !== combineProps.gridHeight ||
            prevProps?.selectionMode !== combineProps.selectionMode ||
            prevProps?.showActionButtons !== combineProps.showActionButtons ||
            prevProps?.editable !== combineProps.editable ||
            prevProps?.editMode !== combineProps.editMode ||
            prevProps?.showDeleteButton !== combineProps.showDeleteButton ||
            prevProps?.isRowDrag !== combineProps.isRowDrag
        )
        ) {
            const colDefs: any[] = gridRef && gridRef?.current?.api?.getColumnDefs();
            const updatedColDefs = getAllColumns(colDefs ?? [], combineProps);

            setColumns([...updatedColDefs ?? []]);
            setPrevProps({ ...combineProps });
        }
    }, [cellClassRules, columnBodyTemplate, combineProps, combineProps.cellRenderer, combineProps.editMode, combineProps.editable, combineProps.gridHeight,
        combineProps.isRowDrag, combineProps.rowSelection, combineProps.selectionMode, combineProps.showActionButtons, combineProps.showActionColumn,
        combineProps.showDeleteButton, combineProps.showPaginator, getAllColumns, isGridReady, prevProps?.editMode, prevProps?.editable, prevProps?.gridHeight,
        prevProps?.isRowDrag, prevProps?.selectionMode, prevProps?.showActionButtons, prevProps?.showActionColumn, prevProps?.showDeleteButton, props, selectionMode]);

    return (
        <>
            <SystemPreferenceProvider>
                <TableHeader>
                    {(typeof headerLabel === 'string' ?
                        <StyledHeaderLabel dangerouslySetInnerHTML={{ __html: headerLabel }} /> : <StyledHeaderLabel>{headerLabel}</StyledHeaderLabel>)}
                    {showHeaderIcons && <div className='table-actionbar'>
                        {showReloadIcon && <IconButton fileName="Icon-reload" size="medium" toolTipArrow={false} toolTipPlacement="left" toolTipText={'Reload'} onClick={onReload} />}
                        {showAddIcon && <IconButton id={addButtonId} fileName="Icon-add" size="medium" toolTipArrow={false} toolTipPlacement="left" toolTipText={'Add'} onClick={onAddClick} />}
                        {showUploadIcon && <IconButton fileName="Icon-upload" size="medium" toolTipArrow={false} toolTipPlacement="left" toolTipText={'Export'} onClick={exportExcel} />}
                        {props?.headerActionButtons?.map(((item: any) => {
                            return (<IconButton key={item?.id} fileName={item?.icon} idIcon={`${item?.icon}-${id}`} size="medium" toolTipArrow={false} toolTipPlacement="left" toolTipText={item?.title} onClick={() => onHeaderActionButtonHandler(item)} />);
                        }))}
                    </div>}
                </TableHeader>
                {getQuickSearchBar()}
                <div ref={tableRef} id={id} className={`grid-wrapper ag-theme-alpine ${gridHeight === 'fullHeight' ? 'grid-full-height' : ''} ${!props.isRowHighligted ? 'remove-highlight' : ''}`} style={{ height: `${gridHeight === 'customHeight' ? customHeight : ''}`, width: '100%' }}>
                    <div className={`ag-table ${showSidebar ? 'ag-grid-width' : ''} pivot-mode-off`} style={{ height: '100%', width: '100%' }}>
                        <AgGridReact
                            ref={gridRef}
                            rowData={data ?? []}
                            columnDefs={columns}
                            defaultColDef={defaultColDef}
                            suppressContextMenu={true}
                            pagination={showPaginator}
                            paginationPageSize={row}
                            rowHeight={rowHeight}
                            headerHeight={headerHeight}
                            columnMenu={'legacy'}
                            onGridReady={onGridReady}
                            getRowClass={getRowClass}
                            domLayout={props.gridHeight === 'autoHeight' ? 'autoHeight' : 'normal'}
                            rowSelection={rowSelection()}
                            gridOptions={gridOptions()}
                            onRowEditingStopped={onRowEditingStopped}
                            onRowEditingStarted={onRowEditingStarted}
                            onCellEditingStopped={(params) => { onCellEditingStopped(params, data, setData) }}
                            suppressClickEdit={editMode === 'row'}
                            editType={editMode === 'row' ? 'fullRow' : undefined}
                            onRowDoubleClicked={(e) => onRowDoubleClick && onRowDoubleClick(e, e.data, id)}
                            onRowClicked={onRowClicked}
                            postProcessPopup={postProcessPopup}
                            overlayNoRowsTemplate='No Record Found'
                            rowDragManaged={isRowDrag}
                            animateRows={isRowDrag}
                            onRowDragEnd={onRowDragEnd}
                            suppressMoveWhenRowDragging={true}
                            // sideBar={sideBar}
                            statusBar={showStatusBar ? statusBar : undefined}
                            // autoSizeStrategy={autoSizeStrategy}
                            onColumnPivotModeChanged={handlePivotMode}
                            {...props.rowModelType ? { serverSideStoreType: 'partial', rowModelType: props.rowModelType, cacheBlockSize: Number(props.cacheBlockSize) } : {}}
                            {...rest}
                        />
                        {showSidebar && <div className='ag-side-panel'>
                            <div className='ag-side-panel-icon'>
                                <IconButton idIcon='setting-button' fileName="Icon-column-setting" size="medium" toolTipArrow={false} toolTipPlacement="left" toolTipText={'Column Setting'} onClick={onColumnSettingClick} />
                                {/* <IconButton fileName="Icon-save" size="medium" toolTipArrow={false} toolTipPlacement="left" toolTipText={'Save'} onClick={()=>saveGridState()} /> */}
                                {memoPreferenceMenu}
                                {columnSettingIconList}
                            </div>
                        </div>}
                    </div>
                    <GridStyles />
                </div>
                <div className='im-charge-data-search-confirm-modal-container'>
                    <SaveNewPreferenceModal visible={isShowSaveNewConfirmModal} entryPoint={props.entrypoint ?? workspaceState.entrypoint ?? ""}
                        gridRef={gridRef} showSaveModal={showSaveModel} tableId={id} autoMarginLeft={autoMarginLeft} />
                    <DeletePreferenceModal visible={isShowDeleteConfirmModal} entryPoint={props.entrypoint ?? workspaceState.entrypoint ?? ""}
                        gridRef={gridRef} showDeleteModal={showDeleteModel} tableId={id} autoMarginLeft={autoMarginLeft} />
                </div>
            </SystemPreferenceProvider>
        </>
    );
});

export default memo(NbisTable);

