import { CustomCellRendererProps } from "ag-grid-react";
import { AbstractEntity } from "domain/entity/Common/AbstractEntity";
import _ from "lodash";
import moment from "moment";
import { DECIMAL_1, DECIMAL_2, DECIMAL_3, DECIMAL_4, formatNumber } from "presentation/utils/numberUtil";
import { convertDateToDateStr, convertDateToDateTimeStr, convertDateToLocal, convertDateToTimeStr } from "presentation/utils/timeUtil";

export const calculateStateColor = (fieldName: string, row: any) => {
    let result: { [key: string]: string } = {};
    if (fieldName && row) {
        const fieldValue: string = (row[fieldName] as unknown as string);
        result[fieldName] = fieldValue;

        let tempFieldName: string = fieldName;
        if (fieldName === 'dtlState' && fieldValue) {
            tempFieldName = tempFieldName + 'Temp';
            let dtlState = fieldValue;
            if (dtlState === 'R') {
                dtlState = 'R - Normal';
            } else if (dtlState === 'F') {
                dtlState = 'F - One-off Rate applied';
            } else if (dtlState === 'E') {
                dtlState = 'E - Error';
            } else if (dtlState === 'C') {
                dtlState = 'C - Confirmed';
            } else if (dtlState === 'DI') {
                dtlState = 'DI - Draft Invoice';
            } else if (dtlState === 'I') {
                dtlState = 'I - Invoice Issued';
            } else if (dtlState === 'T') {
                dtlState = 'T - Account Terminal by System';
            } else if (dtlState === 'MT') {
                dtlState = 'MT - Account Terminal by User';
            } else if (dtlState === 'H') {
                dtlState = 'H - Hold by User';
            } else if (dtlState === 'MH') {
                dtlState = 'MH - Hold by Master Hold';
            } else if (dtlState === 'SH') {
                dtlState = 'SH - Hold by System';
            } else if (dtlState === 'SG') {
                dtlState = 'SG - Processing by System';
            } else if (dtlState === 'SA') {
                dtlState = 'SA - Processing by System';
            } else if (dtlState === 'X') {
                dtlState = 'X - Dummy';
            } else if (dtlState === 'Z') {
                dtlState = 'Z - Dummy';
            }
            result[tempFieldName] = dtlState;
        }

        const className = tempFieldName + "Class";

        if (fieldValue === "SG" || fieldValue === "SA" || fieldValue === "E" || fieldValue === "FAIL" || fieldValue === "FAILED" || fieldValue === "Pending") {
            result[className] = "red";
        } else if (fieldValue === "I" || fieldValue === "DI" || fieldValue === "T" || fieldValue === "MT"
            || fieldValue === "CFM" || fieldValue === "INV" || fieldValue === "TML" || fieldValue === "COMPLETED"
            || fieldValue === "Sent") {
            result[className] = "grey";
        } else if (fieldValue === "R" || fieldValue === "F" || fieldValue === "NEW" || fieldValue === "NIL" || "STD") {
            result[className] = "green";
        } else {
            result[className] = "blue";
        }
    }
    return result;
}

export const defineColumn = (col: any, index: number, dateFieldList?: string[], dateTimeFieldList?: string[],
    timeFieldList?: string[], cellRenderers?: { [key: string]: (params: CustomCellRendererProps) => {} }, numberFieldList?: string[]) => {
    if (Object.keys(cellRenderers ?? {}).includes(col.field)) {
        return {
            ...col,
            header: col.headerName,
            id: index + 1,
            dataType: 'text',
            // filter: getColumnFilterByDataType('text'),
            cellRenderer: cellRenderers && cellRenderers[col.field] ? cellRenderers[col.field] : {}
        }
    } else if (col.dataType === "date") {
        dateFieldList?.push(col.field);
        return {
            ...col,
            header: col.headerName,
            // field: col.field + "Date",
            fieldExtend: col.field + "Date",
            // filter: getColumnFilterByDataType('date'),
            id: index + 1,
        }
    } else if (col.dataType === "dateTime") {
        dateTimeFieldList?.push(col.field);
        return {
            ...col,
            header: col.headerName,
            // field: col.field + "Datetime",
            fieldExtend: col.field + "Datetime",
            // filter: getColumnFilterByDataType('date'),
            id: index + 1,
        }
    } else if (col.dataType === "time") {
        timeFieldList?.push(col.field);
        return {
            ...col,
            header: col.headerName,
            // field: col.field + "Time", 
            fieldExtend: col.field + "Time",
            // filter: getColumnFilterByDataType('date'),
            id: index + 1,
            dataType: 'dateTime',
        }
    } else if (col.dataType === DECIMAL_1 || col.dataType === DECIMAL_2 || col.dataType === DECIMAL_3 || col.dataType === DECIMAL_4) {
        let dataTypeStr: string = col.dataType.toString();
        let dataTypeArr = dataTypeStr.split("_");
        numberFieldList?.push(col.field + "_" + dataTypeArr[1]);

        return {
            ...col,
            header: col.headerName,
            field: col.field + "Number",
            fieldExtend: col.field + "Number",
            // filter: getColumnFilterByDataType('number'),
            id: index + 1,
            dataType: 'number'
        }
    } else if (col.dataType === "select") {
        return {
            ...col,
            header: col.headerName,
            id: index + 1,
            dataType: 'select',
            // filter: getColumnFilterByDataType('text'),
        }
    } else {
        return {
            ...col,
            header: col.headerName,
            id: index + 1,
            dataType: 'text',
            // filter: getColumnFilterByDataType('text'),
        }
    }
}

export function transferRowDataInternal<T extends AbstractEntity>(data: T[], dateFieldList?: string[], dateTimeFieldList?: string[],
    timeFieldList?: string[], externalFnctions?: { [fieldName: string]: (fieldName: string, row: any) => {} }, numberFieldList?: string[]): T[] {
    if (data && _.isArray(data)) {
        return data?.map(row => {
            let basicRow: T = {
                ...row,
            };
            _.forEach(
                _.toPairs(externalFnctions), ([key, fun]) => {
                    basicRow = {
                        ...basicRow,
                        ...fun(key, basicRow),
                    }
                }
            );
            dateFieldList?.map(field => {
                basicRow = {
                    ...basicRow,
                    [field + "Date"]: basicRow[field] ? convertDateToDateStr(convertDateToLocal(moment((basicRow[field] as unknown as Date)))) : null,
                }
                return basicRow;
            });
            dateTimeFieldList?.map(field => {
                basicRow = {
                    ...basicRow,
                    [field + "Datetime"]: basicRow[field] ? convertDateToDateTimeStr(convertDateToLocal(moment((basicRow[field] as unknown as Date)))) : null,
                }
                return basicRow;
            });
            timeFieldList?.map(field => {
                basicRow = {
                    ...basicRow,
                    [field + "Time"]: basicRow[field] ? convertDateToTimeStr(convertDateToLocal(moment((basicRow[field] as unknown as Date)))) : null,
                }
                return basicRow;
            });
            numberFieldList?.map(field => {
                let fieldArr = field.split("_");
                let fieldName = fieldArr[0];
                let decimalPlaces = fieldArr[1] as unknown as number;

                basicRow = {
                    ...basicRow,
                    [fieldName + "Number"]: (basicRow[fieldName] !== null && basicRow[fieldName] !== undefined) ? formatNumber(basicRow[fieldName] as unknown as number, decimalPlaces) : null,
                }
                return basicRow;
            });

            return basicRow;
        });
    }
    return [];
}

export const getColumnFilterByDataType = (dataType: string) => {
    switch (dataType) {
        case "number":
            return "agNumberColumnFilter";
        case "text":
            return "agTextColumnFilter";
        case "date":
            return "agDateColumnFilter";
        case "set":
            return "agSetColumnFilter";
        default:
            return "agTextColumnFilter";
    }
}