import { RequisitionFormCustomerEntity } from "./RequisitionFormCustomerEntity"
import { RequisitionFormMgrEntity } from "./RequisitionFormMgrEntity"

export interface RequisitionFormEntity {
  id: number,
  reqFormNo: string,
  explanation1: string | null,
  explanation2: string | null,
  explanation3: string | null,
  explanation4: string | null,
  explanation5: string | null,
  explanation6: string | null,
  reqFormDesc: string | null,
  reqNature: string | null,
  reqMode: string | null,
  reqRemarks: string | null,
  supportingRemarks: string | null,
  problemLogNo: string | null,
  referenceType: string | null,
  currencyCode: string | null,
  revenueControlInd: string | null,
  amount: number | null,
  state: string | null,
  createdBy: string | null,
  createdDateTime: Date | null,
  billToCompany?: string | null,
  customerCode?: string | null,
  natureDesc?: string | null,
  managerIc?: Date | null,
  acMgr?: Date | null,
  fin?: Date | null,
  com?: Date | null,
  md?: Date | null,
  gmd?: Date | null,

  customers?: RequisitionFormCustomerEntity[] | null,
  mgrs?: RequisitionFormMgrEntity[] | null,

  [key: string]: string | boolean | number | null | undefined | Object

}

export const EMPTY_REQUISITION_FORM_ENTITY: RequisitionFormEntity = {
  id: 0,
  reqFormNo: '',
  explanation1: null,
  explanation2: null,
  explanation3: null,
  explanation4: null,
  explanation5: null,
  explanation6: null,
  reqFormDesc: null,
  reqNature: null,
  reqMode: null,
  reqRemarks: null,
  supportingRemarks: null,
  problemLogNo: null,
  referenceType: null,
  currencyCode: null,
  revenueControlInd: null,
  amount: null,
  state: null,
  createdBy: null,
  createdDateTime: null,
  billToCompany: null,
  customerCode: null,
  natureDesc: null,
  managerIc: null,
  acMgr: null,
  fin: null,
  com: null,
  md: null,
  gmd: null,
  customers: null,
  mgrs: null
}