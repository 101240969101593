import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useMessageBarTracked } from "presentation/store/MessageBarProvider";
import { FC, useCallback } from "react";
import { Notification } from "./Notification";


type Props = {
    children?: React.ReactNode
};

export const MessageBarChildWrapper: FC<Props> = ({
    children
}) => {
    const [messageBarState] = useMessageBarTracked();
    const messageBarVM = useMessageBarVM();
    const onCloseMsgBar = useCallback(() => {
        messageBarVM.showClose();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [messageBarVM]);
    return (
        <>
            {children}
            <div style={{zIndex:"99"}}>
                <Notification                
                    notificationMessage={messageBarState.message}
                    isNotificationError={messageBarState.isNotificationError}
                    isWarning={messageBarState.isWarning}
                    onClearNotification={onCloseMsgBar}
                    isActiveScreen={messageBarState.isActiveScreen}
                    isTime={5}
                />
            </div>
        </>
    )
}