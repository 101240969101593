import styled from 'styled-components';

export const NbisFilterListWrapper = styled.div`
display: flex;
flex-direction: column;

& .column-setting-header {
    display: flex;
    flex-direction: row;
    padding: 10px;
    background-color: transparent;
    border-bottom: 1px solid #222222;

    .not-all-check{
        .p-checkbox-box{
            position:relative;
            background:#999999;
            border: 1px solid #999999;
            &:before{
                content:"";
                width:8px;
                height:2px;
                background:white;
            }
        }
    }

    &:hover {
        border-bottom-color: #009bde;
    }

    & .input-box {
        flex: 1 1 100%;

        & input {
            width: 100%;
            border: 0;
            padding: 2px 10px;
            outline: none;
            background-color: #ffffff;
            font-size: 12px;
            font-family: Montserrat, serif;
        }
    }

    & .iconButton {
        flex: 1 1 10%;

        & .navigation-button:hover {
            background: transparent;
            border-radius: 0;
        }

        & .navigation-button::after {
            display: none;
        }
    }
}

& .column-setting-box {
    display: block;
    min-height: 40px;
    max-height: 100%;
    overflow: hidden;
    overflow-y: auto;

    & ul {
        display: block;
        padding: 10px;
        margin: 0px;

        &>li {
            display: flex;
            padding: 0;
            flex-direction: row;
            padding-right: 10px;
            width: 245px;

            &>div:first-child,&>div:nth-child(2),&>div:last-child {
                flex-grow: 0;
            }

            & button {
                display: inline-block;
                text-align: left;
                background-color: transparent;
                border: 0px;
                padding: 0px;
                margin: 0px;
                cursor: pointer;
                width: calc(100% - 99px);
                line-height: 32px;
                height: 32px;
                flex-grow: 1;
                overflow: hidden;

                & .highlight {
                    font-weight: bold;
                    color: #009BDE;
                }

                &:hover {
                    background-color: #f1f2f3;
                    color: #009BDE;
                }

                & .iconButton {
                    color: #002e6d;
                    padding: 2px 0px 4px;

                    & .navigation-button:after {
                        display: none;
                    }

                    & svg {
                        height: 18px;
                        width: 18px;
                    }
                }

                & span.m-item {
                    font-family: Montserrat, serif;
                    font-size: 12px;
                    cursor: pointer;
                    width: 100%;
                    text-align: left;
                    white-space: nowrap;
                    color: #495057;
                }
            }
        }
    }
}
`;