import { reqFormStateCheckboxOption } from "presentation/constant/CheckBox/StaticCheckboxOptions";
import { RequisitionFormConstant } from "presentation/constant/RequisitionForm/RequisitionFormConstant";
import { useRequisitionFormVM } from "presentation/hook/RequisitionForm/useRequisitionFormVM";
import { useRequisitionFormMaintenanceTracked } from "presentation/store/RequisitionForm/RequisitionFormMaintenanceProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import { memo, useMemo } from "react";
import { FieldType, GroupCheckboxList, HPHGroupCheckbox, IFieldValue } from "veronica-ui-component/dist/component/core";
const RequisitionFormSearchForm = () => {    

    const [ requisitionFormState ] = useRequisitionFormMaintenanceTracked();
    const requisitionFormVM = useRequisitionFormVM();

    const {dynamicOptions} = requisitionFormState;
    const requisitionFormSearchCriteria = requisitionFormState.searchCriteria;
    const REQ_FORM_CONSTANT = RequisitionFormConstant;

    const memoState = useMemo(() =>
        <div className='flex-row-item'>
            <HPHGroupCheckbox
                label={REQ_FORM_CONSTANT.COMMON.STATE}
                checkboxData={reqFormStateCheckboxOption}
                selectedValues={requisitionFormSearchCriteria.states}
                orientation={"horizontal"}
                onChange={(e: (GroupCheckboxList | undefined)[]) => requisitionFormVM.onSearchGroupCheckboxChange(e, requisitionFormSearchCriteria, "states")}
            />

        </div>
        , [REQ_FORM_CONSTANT.COMMON.STATE, requisitionFormSearchCriteria, requisitionFormVM])
    
    const memoBillToCompany = useMemo(() =>
        <div className='flex-row-item' style={{width:"180px", marginBottom:"24px"}}>
            <NbisCommonField
                isReadOnly={false}
                isShowOptional={false}
                isMultipleDropDown={true}
                readOnlyValue={requisitionFormSearchCriteria.billToCompanies?.map((item) => item).join(',') ?? ''}
                fieldValue={requisitionFormSearchCriteria.billToCompanies ?? ''}
                fieldLabel={REQ_FORM_CONSTANT.COMMON.BILL_TO_COMPANY}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'billToCompanies'}
                options={dynamicOptions.billToCompanyDropdownOptions}
                maxLength={60}
                sort={false}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    requisitionFormVM.onSearchCriteriaFieldChange(fieldKey, fieldValue && fieldValue as string[], fFullValue)} />
        </div>
        , [REQ_FORM_CONSTANT.COMMON.BILL_TO_COMPANY, dynamicOptions.billToCompanyDropdownOptions, requisitionFormSearchCriteria.billToCompanies, requisitionFormVM])

    const memoCustomerCode = useMemo(() =>
        <div className='flex-row-item' style={{width:"180px", marginBottom:"24px"}}>
            <NbisCommonField
                isReadOnly={false}
                isShowOptional={false}
                isMultipleDropDown={false}
                readOnlyValue={requisitionFormSearchCriteria.customerCode ?? ''}
                fieldValue={requisitionFormSearchCriteria.customerCode ?? ''}
                fieldLabel={REQ_FORM_CONSTANT.COMMON.CUSTOMER_CODE}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'customerCode'}
                options={dynamicOptions.customerCodeDropdownOptions}
                maxLength={60}
                sort={false}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    requisitionFormVM.onSearchCriteriaFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [REQ_FORM_CONSTANT.COMMON.CUSTOMER_CODE, dynamicOptions.customerCodeDropdownOptions, requisitionFormSearchCriteria.customerCode, requisitionFormVM])
    
    const memoReqMode = useMemo(() =>
        <div className='flex-row-item' style={{ width: "180px", marginBottom: "24px" }}>

            <NbisCommonField
                isReadOnly={false}
                isShowOptional={false}
                isMultipleDropDown={false}
                readOnlyValue={
                    dynamicOptions.reqModeDropdownOptions?.find(item => item.tagLabel === requisitionFormSearchCriteria.reqMode)?.value??""
                }
                fieldValue={dynamicOptions.reqModeDropdownOptions?.find(item => item.tagLabel === requisitionFormSearchCriteria.reqMode)?.value??""}
                fieldLabel={REQ_FORM_CONSTANT.COMMON.REQ_MODE}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'reqMode'}
                options={dynamicOptions.reqModeDropdownOptions}
                maxLength={60}
                sort={false}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    requisitionFormVM.onSearchCriteriaFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [REQ_FORM_CONSTANT.COMMON.REQ_MODE, requisitionFormSearchCriteria.reqMode, dynamicOptions.reqModeDropdownOptions, requisitionFormVM])

    const memoReqNature = useMemo(() =>
        <div className='flex-row-item' style={{ width: "180px", marginBottom: "24px" }}>
            <NbisCommonField
                isReadOnly={false}
                isShowOptional={false}
                isMultipleDropDown={false}
                readOnlyValue={dynamicOptions.reqNatureDropdownOptions?.find(item => item.tagLabel === requisitionFormSearchCriteria.reqNature)?.value??""}
                fieldValue={dynamicOptions.reqNatureDropdownOptions?.find(item => item.tagLabel === requisitionFormSearchCriteria.reqNature)?.value??""}
                fieldLabel={REQ_FORM_CONSTANT.COMMON.REQ_NATURE}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'reqNature'}
                options={dynamicOptions.reqNatureDropdownOptions}
                maxLength={60}
                sort={false}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    requisitionFormVM.onSearchCriteriaFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [REQ_FORM_CONSTANT.COMMON.REQ_NATURE, dynamicOptions.reqNatureDropdownOptions, requisitionFormSearchCriteria.reqNature, requisitionFormVM])

    const memoReqNoFrom = useMemo(() =>
        <div className='flex-row-item' style={{ width: "180px", marginBottom: "24px" }}>            
            <NbisCommonField
                isReadOnly={false}
                isShowOptional={false}                
                readOnlyValue={requisitionFormSearchCriteria.reqNoFrom ?? ''}
                fieldValue={requisitionFormSearchCriteria.reqNoFrom ?? ''}
                fieldLabel={REQ_FORM_CONSTANT.Header.REQ_NO_FROM}
                fieldType={FieldType.TEXT}
                fieldKey={'reqNoFrom'}
                maxLength={60}
                sort={false}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    requisitionFormVM.onSearchCriteriaFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [REQ_FORM_CONSTANT.Header.REQ_NO_FROM, requisitionFormSearchCriteria.reqNoFrom, requisitionFormVM])

    const memoReqNoTo = useMemo(() =>
        <div className='flex-row-item' style={{ width: "180px", marginBottom: "24px" }}>            
            <NbisCommonField
                isReadOnly={false}
                isShowOptional={false}                
                readOnlyValue={requisitionFormSearchCriteria.reqNoTo ?? ''}
                fieldValue={requisitionFormSearchCriteria.reqNoTo ?? ''}
                fieldLabel={REQ_FORM_CONSTANT.Header.REQ_NO_TO}
                fieldType={FieldType.TEXT}
                fieldKey={'reqNoTo'}
                maxLength={60}
                sort={false}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    requisitionFormVM.onSearchCriteriaFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [REQ_FORM_CONSTANT.Header.REQ_NO_TO, requisitionFormSearchCriteria.reqNoTo, requisitionFormVM])
        
    return <> 
        <CriteriaItemContainer>
            {memoState}
        </CriteriaItemContainer>
        <CriteriaItemContainer>
            {memoBillToCompany}
            {memoCustomerCode}
        </CriteriaItemContainer>
        <CriteriaItemContainer>
            {memoReqMode}
            {memoReqNature}
        </CriteriaItemContainer>
        <CriteriaItemContainer>
            {memoReqNoFrom}
            {memoReqNoTo}
        </CriteriaItemContainer>
    </>;
}
export default memo(RequisitionFormSearchForm);