import { ParameterDetailEntity } from "domain/entity/Parameter/ParameterDetailEntity";
import _ from "lodash";
import { axiosDeleteData, axiosGetData, axiosPostData, axiosPutData } from "../axios/AxiosBasicImpl";
import referenceAxiosInstance from "../axios/referenceAxiosInstance";
import { ParameterDetailRepository } from "./ParameterDetailRepo";
import { ParameterSearchCriteria } from "domain/entity/Parameter/ParameterSearchCriteria";

export const ParameterDetailRepoImpl = (): ParameterDetailRepository => {
    const url = '/v1/parameterDtl';
    const getSecondDtlByCodeAndDtlCodeUrl = '/v1/parameterSecondDtlByParaCodeAndParaDtlCode';
    const getThirdDtlByCodeAndDtlCodeUrl = '/v1/parameterThirdDtlByParaCodeAndParaDtlCode';

    const getAllParameterDtlsByParameterCode = async(parameterCode:string) : Promise<ParameterDetailEntity[]> => {
        return axiosGetData(referenceAxiosInstance, `${url}/parameterCode/${parameterCode}`, []).then(res => {
            const data = res.data;
            return _.sortBy(data, ["key"]);
        }).catch(err => {
            return [];
        });
    }

    const getParameterSecondDtlByParaCodeAndParaDtlCode = async(parameterCode:string, parameterDtlCode: string): Promise<ParameterDetailEntity> => {
        return axiosGetData(referenceAxiosInstance, `${getSecondDtlByCodeAndDtlCodeUrl}/${parameterCode}/${parameterDtlCode}`, []).then(res => {
            return res.data;
        }).catch(err => {
            return null;
        });
    }

    const getParameterThirdDtlByParaCodeAndParaDtlCode = async(parameterCode:string, parameterDtlCode: string): Promise<ParameterDetailEntity[]> => {
        return axiosGetData(referenceAxiosInstance, `${getThirdDtlByCodeAndDtlCodeUrl}/${parameterCode}/${parameterDtlCode}`, []).then(res => {
            return res.data;
        }).catch(err => {
            return null;
        });
    }

    const searchParamDtlBySearchCriteria = async(paramSearchCriteria: ParameterSearchCriteria) : Promise<ParameterDetailEntity[]> => {
        let newUrl = '/v1/parameterDtlSearch';
        
        return axiosPostData(referenceAxiosInstance, `${newUrl}`, paramSearchCriteria).then(res => {
            const data = res.data;
            return _.sortBy(data, ["key"]);
        }).catch(err => {
            return [];
        });
    }

    const createNewParameterDtl = async(newData: ParameterDetailEntity) : Promise<ParameterDetailEntity> => {
        const result = axiosPostData(referenceAxiosInstance, `${url}`, newData).then(res => {
            return res.data;
        }).catch(err => {
            let errorMessage = "Update Parameter Detail Code Failure"
            if (err && err.response && err.response.data && typeof (err.response.data) === "string") {
                if (err.response.data.includes("already exist")) {
                    errorMessage = `"Parameter Detail Code ${newData.parameterDtlCode}" already exists.`
                }
            }
            throw new Error(errorMessage);
        });
        return result;
    }

    const updateParameterDtl = async(updatedData: ParameterDetailEntity): Promise<ParameterDetailEntity> => {
        const result = axiosPutData(referenceAxiosInstance, `${url}`, updatedData).then(res => {
            return res.data
        }).catch(error => {
            return null
        })
        return result
    }

    const deleteParameterDtlByKey = async(key: string): Promise<boolean> => {
        return axiosDeleteData(referenceAxiosInstance, `${url}`, key).then(res => {
            return res.status === 200
        }).catch(error => {
            return false
        })
    }

    return {
        getAllParameterDtlsByParameterCode: getAllParameterDtlsByParameterCode,
        getParameterSecondDtlByParaCodeAndParaDtlCode: getParameterSecondDtlByParaCodeAndParaDtlCode,
        getParameterThirdDtlByParaCodeAndParaDtlCode: getParameterThirdDtlByParaCodeAndParaDtlCode,
        searchParamDtlBySearchCriteria: searchParamDtlBySearchCriteria,
        createNewParameterDtl: createNewParameterDtl,
        updateParameterDtl: updateParameterDtl,
        deleteParameterDtlByKey: deleteParameterDtlByKey
    }
}