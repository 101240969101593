import { CompanyRepoImpl } from "domain/repository/Company/CompanyRepoImpl";
import { CustomerRepoImpl } from "domain/repository/Company/CustomerRepoImpl";
import { ExchangeRateRepoImpl } from "domain/repository/ExchangeRate/ExchangeRateRepoImpl";
import { ParameterDetailRepoImpl } from "domain/repository/parameter/ParameterDetailRepoImpl";
import { RequisitionFormRepoImpl } from "domain/repository/RequisitionForm/RequisitionFormRepoImpl";
import { useRequisitionFormMaintenanceTracked } from "presentation/store/RequisitionForm/RequisitionFormMaintenanceProvider";
import { RequisitionFormVM } from "presentation/viewModel/RequisitionForm/RequisitionFormVM";
import { useMemo } from "react";

export const useRequisitionFormVM = () => {
    const [, setRequisitionFormState] = useRequisitionFormMaintenanceTracked();
    const requisitionFormVM = useMemo(() =>
        RequisitionFormVM({
            dispatch: [setRequisitionFormState],
            requisitionFormRepo: RequisitionFormRepoImpl(),
            companyRepo: CompanyRepoImpl(),
            customerRepo: CustomerRepoImpl(),
            parameterDtlRepo: ParameterDetailRepoImpl(),
            exchangeRateRepo: ExchangeRateRepoImpl(),
        }), [setRequisitionFormState])

    return requisitionFormVM
}

