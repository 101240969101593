import type { SystemPreferenceEntity } from "domain/entity/SystemPreference/SystemPreferenceEntity";
import authAxiosInstance from "domain/repository/axios/authAxiosInstanc";
import { axiosDeleteData, axiosPostData, axiosPutData } from "domain/repository/axios/AxiosBasicImpl";
import type { SystemPreferenceRepository } from "domain/repository/SystemPreference/SystemPreferenceRepo";
import _ from "lodash";
import { EMPTY_SYSTEM_PREFERENCE_SEARCH_CRITERIA, type SystemPreferenceSearchCriteria } from "presentation/model/SystemPreference/SystemPreferenceSearchCriteria";

export const SystemPreferenceRepoImpl = (): SystemPreferenceRepository => {
    const url = '/v1/systempreference';
    const getSystemPreferences = async (entryPoint: string, tableId: string, userName: string): Promise<SystemPreferenceEntity[]> => {
        const searchCriteria: SystemPreferenceSearchCriteria = {
            ...EMPTY_SYSTEM_PREFERENCE_SEARCH_CRITERIA,
            type: 'Table',
            key: `${entryPoint}/${tableId}`,
            userId: userName,
            onlySelfSetting: false
        }
        return await axiosPostData(authAxiosInstance, `/v1/systempreferencesearch`, searchCriteria).then(res => {
            const data = res.data;
            return _.sortBy(data, ["name"]);
        }).catch(err => {
            return [];
        });
    }

    const createNewSystemPreference = async (newData: SystemPreferenceEntity): Promise<SystemPreferenceEntity> => {
        return await axiosPostData(authAxiosInstance, `${url}`, newData).then((res) => {
            if (res.msg === "OK") {
                return res.data;
            } else {
                let errorMessage = "Create System Preference Failure"
                if (res.data && typeof (res.data) === "string") {
                    if (res.data.includes("Already Exist")) {
                        errorMessage = `System Preference "${newData.name}" already exists.`
                    } else {
                        errorMessage = res.data;
                    }
                }
                return "Error:" + errorMessage;
            }
        }).catch(err => {
            return "Error:" + err.message;
        });
    }

    const updateSystemPreference = async (updatedData: SystemPreferenceEntity): Promise<SystemPreferenceEntity> => {
        return await axiosPutData(authAxiosInstance, `${url}`, updatedData).then(res => {
            if (res.msg === "OK") {
                return res.data;
            } else {
                let errorMessage = "Update System Preference Failure"
                if (res.data && typeof (res.data) === "string") {
                    if (res.data.includes("Already Exist")) {
                        errorMessage = `System Preference "${updatedData.name}" already exists.`
                    } else {
                        errorMessage = res.data;
                    }
                }
                return "Error:" + errorMessage;
            }
        }).catch(error => {
            return "Error:" + error.message;
        })
    }

    const deleteSystemPreference = async (deleteData: SystemPreferenceEntity): Promise<boolean> => {
        if (!deleteData || !deleteData.id) return false;

        return await axiosDeleteData(authAxiosInstance, `${url}`, `${deleteData.id}`).then(res => {
            return res.status === 200
        }).catch(error => {
            return false
        })
    }

    return {
        getSystemPreferences: getSystemPreferences,
        createNewSystemPreference: createNewSystemPreference,
        updateSystemPreference: updateSystemPreference,
        deleteSystemPreference: deleteSystemPreference,
    }
}