import { CustomCellEditorProps } from 'ag-grid-react';
import { Dropdown } from 'primereact/dropdown';

import { InputText } from 'primereact/inputtext';
import { HPHCheckbox } from 'veronica-ui-component/dist/component/core/CheckBox/CheckBox';
import { ColorPicker } from 'veronica-ui-component/dist/component/core/ColorPicker/ColorPicker';
import { DatePicker } from 'veronica-ui-component/dist/component/core/DatePicker/DatePicker';
import { DateTimePicker } from 'veronica-ui-component/dist/component/core/DateTimePicker/DateTimePicker';

export interface MyCustomCellEditorProps extends CustomCellEditorProps {
    colDef: any;
}
const Editors = ({ value, onValueChange, stopEditing, colDef }: MyCustomCellEditorProps) => {
    /* istanbul ignore next */
    const updateValue = (val: any) => {
        onValueChange(val);
    }

    /* istanbul ignore next */
    const textEditor = () => {
        return <InputText
            type="text"
            autoFocus
            value={value}
            onChange={(e) => updateValue(e.target.value)}
            className='inputField'
        />;
    };

    /* istanbul ignore next */
    const editorDropdownList = () => {
        let arr = colDef?.options;
        let key = 'label';
        return arr.sort((a: any, b: any) => a[key].localeCompare(b[key]));
    };

    /* istanbul ignore next */
    const dropdownEditor = () => {
        return <Dropdown
            value={value}
            options={editorDropdownList()}
            optionLabel="label"
            optionValue="value"
            onChange={(e) => updateValue(e.value)}
            className='dropdown'
        />;
    };

    /* istanbul ignore next */
    const dateTimeEditor = () => {
        return <DateTimePicker
            label=''
            value={value}
            onChange={(e) => updateValue(e)}
            className='dateTime'
            width='100%'
        />;
    };

    const dateEditor = () => {
        return <DatePicker
            label=''
            value={value}
            onChange={(e) => updateValue(e)}
            className='dateTime'
            width='100%'
        />;
    };

    /* istanbul ignore next */
    const checkEditor = () => {
        return <HPHCheckbox label="" checked={value} onChange={(e) => updateValue(e.checked)} className='checkBox' />;
    };

    /* istanbul ignore next */
    const colorEditor = () => {
        return <ColorPicker
            label=""
            value={value}
            toolTipText="Help Icon"
            disabled={false}
            onColorChange={(e) => updateValue('#' + e)}
            onInputKeyDown={(e) => updateValue('#' + e)}
            className='colorPicker'
        />;
    };

    /* istanbul ignore next */
    const tableEditor = (column: any) => {
        let editor;
        switch (column.dataType) {
            case 'text':
                editor = textEditor();
                break;
            case 'dateTime':
                editor = dateTimeEditor();
                break;
            case 'select':
                editor = dropdownEditor();
                break;
            case 'color':
                editor = colorEditor();
                break;
            case 'date':
                editor = dateEditor();
                break;
            case 'boolean':
                editor = checkEditor();
                break;
        }
        return editor;
    };

    /* istanbul ignore next */
    return <div className='tableEditor'>
        {tableEditor(colDef)}
    </div>
}

export default Editors;
