import type { CustomCellRendererProps } from "ag-grid-react";
import { defineColumn, transferRowDataInternal } from "presentation/view/components/TableWrapper/BasicTableConstants";
import { RequisitionFormConstant } from "./RequisitionFormConstant";

const REQ_FORM_CONSTANT = RequisitionFormConstant.COMMON;

export const INITIAL_REQUISITION_FORM_CUST_COL_DEF: any[] = [
    {
        headerName: REQ_FORM_CONSTANT.BILL_TO_COMPANY,
        field: 'billToCompany',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 180,
        pinned: "left",
        checkboxSelection: true,
        headerCheckboxSelection: true,
    },
    {
        headerName: REQ_FORM_CONSTANT.CUSTOMER_CODE,
        field: 'customerCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    } 

].map((col, index) => {
    const cellRenderers: { [key: string]: ((params: CustomCellRendererProps) => {}) } = {};
    //cellRenderers['hdrState'] = cellRenderWithColorAndCircle;
    return defineColumn(col, index, [], [], [], cellRenderers, []);
}
);

export const transferRowData = (data: any[]) => {
    const externalFnctions: { [key: string]: ((fieldName: string, row: any) => {}) } = {};
    //externalFnctions['hdrState'] = calculateStateColor;
    return transferRowDataInternal(data, [], [], [], externalFnctions, []);
}