import { MessageConstant } from "presentation/constant/MessageConstant";
import { RequisitionFormConstant } from "presentation/constant/RequisitionForm/RequisitionFormConstant";
import { WorkspaceConstant } from "presentation/constant/WorkSpaceConstant";
import { useRequisitionFormVM } from "presentation/hook/RequisitionForm/useRequisitionFormVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useRequisitionFormMaintenanceTracked } from "presentation/store/RequisitionForm/RequisitionFormMaintenanceProvider";
import { ConfirmDeleteModal } from "presentation/view/components/ConfirmDeleteModal";
import { HeaderTitle } from "presentation/view/components/HeaderWithBackButton/HeaderTitle";
import { memo, useCallback, useState } from "react";
import { HPHButton, IconButton } from "veronica-ui-component/dist/component/core";
import { Sidebarheader, StyledAction } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const RequisitionFormTitleBar:React.FC = () => {
    const requisitionFormVM = useRequisitionFormVM();
    const REQ_FORM_CONSTANT = RequisitionFormConstant;
    const [ requisitionFormState ] = useRequisitionFormMaintenanceTracked();
    const messageBarVM = useMessageBarVM();
    const [anaInfoState] = useANAInfoTracked();

    const {selectedRequisitionFormRows} = requisitionFormState;
    const {allowCreate,allowUpdate,allowDelete} = anaInfoState;
    const [ isShowDelete, setIsShowDelete ] = useState<boolean>(false);

    const handleSearch = useCallback(() => {
        requisitionFormVM.onSearchClick();
    }, [requisitionFormVM]);


    const handleAdd = useCallback(async() => {
        requisitionFormVM.onAdd();
    }, [requisitionFormVM]);
        
    const isDisableDelete = useCallback(() => {
        if (selectedRequisitionFormRows && selectedRequisitionFormRows.length > 0) {
            const reqForms = selectedRequisitionFormRows.find(reqForm => reqForm.state === "INV");

            return reqForms ? true : false;
        }

        return true;
    }, [selectedRequisitionFormRows]);

    const handleDelete = useCallback(async() => {        
        setIsShowDelete(false);
        requisitionFormVM.onShowLoading();
        
        requisitionFormVM.onDelete(requisitionFormState.selectedRequisitionFormRows.map(row => row.id).filter((id): id is number => id !== null)).then((data)=>{
            if(data.success){
                messageBarVM.showSuccess("Delete record successful.");
                requisitionFormVM.getRequisitionForms(requisitionFormState.searchCriteria).then(()=>{
                    requisitionFormVM.onHideLoading();
                }).catch((error) => {
                    requisitionFormVM.onHideLoading();
                })
            }else{
                messageBarVM.showError(data.data??'')
                requisitionFormVM.onHideLoading();
            }
        }).catch((error) => {     
            requisitionFormVM.onHideLoading();
            messageBarVM.showError(error.message)
        });
    }, [messageBarVM, requisitionFormState.searchCriteria, requisitionFormState.selectedRequisitionFormRows, requisitionFormVM]);

    const handleDeleteClick = useCallback(() => {
        setIsShowDelete(true);
    }, []);    

    const handleCancel = () => {
        setIsShowDelete(false);
    }
  
    return <Sidebarheader style={{width: '100%', display:"flex", alignItems:"center"}}>
        <HeaderTitle>{REQ_FORM_CONSTANT.TITLE}</HeaderTitle>
        <StyledAction className="tm-animated-wrapper">
            <IconButton fileName='Icon-search' disabled={false} size='medium' toolTipText={'Search'} toolTipArrow={false} onClick={handleSearch} />
            {(allowCreate) && <IconButton fileName='Icon-add' disabled={false} size='medium' toolTipText={'Add'} toolTipArrow={false} onClick={handleAdd} />}
            {(allowUpdate || allowDelete) && <div className="add-seperator"/>}
            {/*<HPHButton disabled={false} label={WorkspaceConstant.Common.BUTTON_REPRINT} size={'Small'} theme={'Secondary'} onClick={()={}}/>*/ }
            {(allowDelete) && <HPHButton disabled={isDisableDelete()} label={WorkspaceConstant.Common.BUTTON_DELETE} size={'Small'} theme={'Secondary'} onClick={handleDeleteClick} />}
            
            {isShowDelete && <ConfirmDeleteModal headerText={`${WorkspaceConstant.Common.BUTTON_DELETE} ${REQ_FORM_CONSTANT.TITLE}`} 
            contentText={`${MessageConstant.common.DELETE_ALERT_TITLE}\n${MessageConstant.common.DELETE_ALERT_MESSAGE}`}
            visible={isShowDelete}
            onCancelClick={handleCancel}
            onDeleteClick={handleDelete}
        />}
        </StyledAction>
    </Sidebarheader>
}

export default memo(RequisitionFormTitleBar);