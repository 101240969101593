import type { SystemPreferenceEntity } from "domain/entity/SystemPreference/SystemPreferenceEntity";

export interface SystemPreferenceModel {
    allSystemPreferences?: SystemPreferenceEntity[],
    allGlobalSystemPreferences?: SystemPreferenceEntity[];
    allUserSystemPreferences?: SystemPreferenceEntity[];
    activeSystemPreference?: SystemPreferenceEntity;
    isShowSaveNewConfirmModal: boolean;
    isShowDeleteConfirmModal: boolean;
}

export const EMPTY_SYSTEM_PREFERENCE_MODEL: SystemPreferenceModel = {
    allSystemPreferences: undefined,
    allGlobalSystemPreferences: undefined,
    allUserSystemPreferences: undefined,
    activeSystemPreference: undefined,
    isShowDeleteConfirmModal: false,
    isShowSaveNewConfirmModal: false,
};