export interface RequisitionFormEnabledSearchCriteria {
    all: boolean,
    states: boolean,
    billToCompanies: boolean,
    reqNoFrom: boolean,
    reqNoTo: boolean,
    coVslVoy: boolean,
    customerCode: boolean,
    originalInvoiceNo: boolean,
    creditChargeNoFrom: boolean,
    creditChargeNoTo: boolean,
    createdBy: boolean,
    createdDate: boolean,

    [key: string]: boolean,
}

export const DEFAULT_ENABLED_REQUISITION_FORM_SEARCH_CRITERIA: RequisitionFormEnabledSearchCriteria = {
    all: true,
    states: true,
    billToCompanies: true,
    reqNoFrom: true,
    reqNoTo: true,
    coVslVoy: true,
    customerCode: true,
    originalInvoiceNo: true,
    creditChargeNoFrom: true,
    creditChargeNoTo: true,
    createdBy: true,
    createdDate: true,
}