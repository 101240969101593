import type { SystemPreferenceEntity } from "domain/entity/SystemPreference/SystemPreferenceEntity";
import { useSystemPreferenceVM } from "presentation/hook/SystemPreference/useSystemPreferenceVM";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useSystemPreferenceTracked } from "presentation/store/SystemPreference/SystemPreferenceProvider";
import { useState } from "react";
import { DialogModal, DropdownOptions, HPHButton, IconButton, InputDropdown } from "veronica-ui-component/dist/component/core";
import { IPreferenceModal } from "./SaveNewPreferenceModal";

export const DeletePreferenceModal: React.FC<IPreferenceModal> = (props: IPreferenceModal) => {
    const { visible } = props;
    const [systemPreferenceState] = useSystemPreferenceTracked();
    const systemPreferenceVM = useSystemPreferenceVM();
    const [anaInfoState] = useANAInfoTracked();
    const [preferenceKey, setPreferenceKey] = useState<string>('');

    const onConfirmClicked = () => {
        const selectedPreference = systemPreferenceState.allSystemPreferences?.filter((preference: SystemPreferenceEntity) =>
            preference.name === preferenceKey)[0]!;
        Promise.allSettled([
            systemPreferenceVM.onDeleteSystemPreference(selectedPreference),
        ]).then(() => {
            systemPreferenceVM.getTableSystemPreferences(props.entryPoint, props.tableId, anaInfoState.userName);
            props?.gridRef?.current?.api?.resetColumnState();
        });

        if (props.showDeleteModal) {
            props.showDeleteModal();
        }
        setPreferenceKey('');
    }

    return <DialogModal
        appendTo='self'
        showButton={false}
        visible={visible}
        positions="center"
        resizable={false}
        header={
            <div className="im-charge-data-search-confirm-modal-header">
                <span className="name">DELETE PREFERENCE</span>
                <IconButton fileName='Icon-cross' size="medium" onClick={() => props.showDeleteModal && props.showDeleteModal()} tooltipDisable={true} />
            </div>
        }
        dialogContent={
            <div className="im-charge-data-search-confirm-modal-content" style={{ marginLeft: `${props.autoMarginLeft ? "auto" : "-25px"}` }}>
                <div className='im-flex-row'>
                    <div className='im-flex-row-item'>
                        <InputDropdown label='Name' inputType="freeText" value={preferenceKey} width={"330px"}
                            onChange={(e: DropdownOptions) => setPreferenceKey(e?.value ?? '')}
                            options={systemPreferenceState?.allSystemPreferences?.filter((preference) => preference.userId === anaInfoState.userName).map(preference => ({
                                dropdownLabel: preference.name,
                                tagLabel: preference.name,
                                value: preference.name,
                            }))} />
                    </div>
                </div>
            </div>
        }
        className="im-charge-data-search-confirm-modal"
        footer={
            <div className="im-charge-data-search-confirm-modal-footer">
                <HPHButton label={'Confirm'} size={'Small'} theme={'Primary'} onClick={onConfirmClicked} />
            </div>
        }
    />;
};
