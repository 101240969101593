import { ChargeCategory } from "constants/charge/ChargeCategory";
import { ChargeConstantCollection } from "constants/charge/ChargeConstantCollection";
import { DocumentConst } from "constants/document/DocumentConst";
import { GroupCheckboxList } from "veronica-ui-component/dist/component/core";

const manHdrStateList: { [key:string] : string; } = ChargeConstantCollection.manHdrState;
export const manHdrStateCheckboxOption: GroupCheckboxList[] = Object.entries(manHdrStateList).filter(state => state[1] !== 'STD').map(([key, name]) => ({
    key,
    name,
    disabled: false,
}));

export const nonOpsHdrStateCheckboxOption: GroupCheckboxList[] = Object.entries(manHdrStateList).map(([key, name]) => ({
    key,
    name,
    disabled: false,
}));


const ediMonitoringStatusList: { [key:string] : string; } = DocumentConst.ediMonitoringStatus;
export const ediMonitoringStatusCheckboxOption: GroupCheckboxList[] = Object.entries(ediMonitoringStatusList).map(([key, name]) => ({
    key,
    name,
    disabled: false,
}));

const docAppStatusList: { [key:string] : string; } = DocumentConst.docuemtApprovalStatus;
export const docAppStatusCheckboxOption: GroupCheckboxList[] = Object.entries(docAppStatusList).map(([key, name]) => ({
    key,
    name,
    disabled: false,
}));

const docIssueTypeList: { [key:string] : string; } = DocumentConst.issueType;
export const issueTypeCheckboxOption: GroupCheckboxList[] = Object.entries(docIssueTypeList).map(([key, name]) => ({
    key: name,
    name: name,
    disabled: false,
}));

const billingTypeList: { [key:string] : string; } = DocumentConst.billingTypePolicy;
export const billingTypeCheckboxOption: GroupCheckboxList[] = Object.entries(billingTypeList).map(([key, name]) => ({
    key: key,
    name: name,
    disabled: false,
}));

export const billingTypeForSOACheckboxOption: GroupCheckboxList[] = Object.entries(DocumentConst.billingTypeForSOAPolicy).map(([key, name]) => ({
    key: key,
    name: name,
    disabled: false,
}));

const ediExportTypes: { [key:string] : string; } = DocumentConst.ediExportType;
export const ediExportTypeCheckboxOption: GroupCheckboxList[] = Object.entries(ediExportTypes).map(([key, name]) => ({
    key: key,
    name: name,
    disabled: false,
}));


export const chargeCategoryCheckboxOption: GroupCheckboxList[] = Object.entries(ChargeCategory).map(([key, name]) => ({
    key: key,
    name: name,
    disabled: false,
}));

const reqFormStateList: { [key:string] : string; } = DocumentConst.reqFormState;
export const reqFormStateCheckboxOption: GroupCheckboxList[] = Object.entries(reqFormStateList).map(([key, name]) => ({
    key: key,
    name: name,
    disabled: false,
}));

const referenceTypeList: { [key:string] : string; } = DocumentConst.referenceType;
export const referenceTypeCheckboxOption: GroupCheckboxList[] = Object.entries(referenceTypeList).map(([key, name]) => ({
    key: key,
    name: name,
    disabled: false,
}));