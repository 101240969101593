import { EMPTY_REQUISITION_FORM_MAINTENANCE_MODEL } from "presentation/model/RequisitionForm/RequisitionFormMaintenanceModel";
import { useState } from "react";
import { createContainer } from "react-tracked";

const {
    Provider: RequisitionFormMaintenanceProvider,
    useTracked: useRequisitionFormMaintenanceTracked
} = createContainer(() => useState(EMPTY_REQUISITION_FORM_MAINTENANCE_MODEL), {concurrentMode: true});
export { RequisitionFormMaintenanceProvider, useRequisitionFormMaintenanceTracked };

