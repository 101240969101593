import { memo } from "react";
import { SliderPanel } from "veronica-ui-component/dist/component/core";
import { useRequisitionFormMaintenanceTracked } from "presentation/store/RequisitionForm/RequisitionFormMaintenanceProvider";
import RequisitionFormDetailRightPanel from "./RequisitionFormDetailRightPanel";
import { RequisitionFormCustFormPanel } from "./Form/RequisitionFormCustFormPanel";


export const RequisitionFormEditPanel: React.FC = () => {
    const [requisitionFormState] = useRequisitionFormMaintenanceTracked();
    const { isSliderOpen } = requisitionFormState.detailState;

    
        return <div className='main-comp-wrapper'>
                <SliderPanel
                    isOpen={isSliderOpen}
                    leftSectionWidth={'60%'}
                    rightSectionWidth={'40%'}
                    draggable={true}
                    leftChildren={<RequisitionFormDetailRightPanel/>}
                    rightChildren={<RequisitionFormCustFormPanel/>} />
            </div>
    }
    
export default memo(RequisitionFormEditPanel);