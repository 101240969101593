export interface MessageBarModel {
    isOpen: boolean;
    isNotificationError: boolean
    message: string;
    errorCountDownFlag: number;
    errorDuration: number;
    isWarning?: boolean;
    isActiveScreen: boolean;
}

export enum MessageBarActionTypes {
    SHOW_ERROR = 'SHOW_ERROR',
    SHOW_SUCCESS = 'SHOW_SUCCESS',
    CLOSE = 'CLOSE',
    SHOW_WARNING = 'SHOW_WARNING,'
}

export interface MessageBarPayLoad {
    [MessageBarActionTypes.SHOW_ERROR]: string;
    [MessageBarActionTypes.SHOW_SUCCESS]: string;
    [MessageBarActionTypes.CLOSE]: undefined;
    [MessageBarActionTypes.SHOW_WARNING]: string;
}

export const EMPTY_MESSAGE_BAR_PROPS: MessageBarModel = {
    isOpen: false,
    isNotificationError: true,
    message: '',
    errorCountDownFlag: -1,
    errorDuration: 0,
    isActiveScreen: false,
}